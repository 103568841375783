import { Link } from 'react-router-dom'
import { Paths } from 'types'

export default function BaremePage() {
  return (
    <div
      id="baremesPage"
      className="page flex-column-center"
    >
      <h1>Barêmes Liste</h1>
      <div className="flex-column-center">
        <Link to={`${Paths.BAREMES}/1`}>Barême Détail 1</Link>
        <Link to={`${Paths.BAREMES}/2`}>Barême Détail 2</Link>
        <Link to={`${Paths.BAREMES}/3`}>Barême Détail 3</Link>
      </div>
    </div>
  )
}
