import terms from 'assets/terms'
import { SarTms } from 'types'
import { ReactElement, useEffect, useState } from 'react'
import { FluxSignal, patchSar, SarSignal } from 'services'
import { CheckInput, Loader, TextInput } from 'components'

import './FluxSar.scss'
import { debounce } from 'lodash'
import BannerInfo from 'components/banner/BannerInfo'
import { sortDays } from 'utils'

export default function FluxSar(): ReactElement {
  const currentSar = SarSignal.value
  const { longueur, poids, regimeCirculation, origine, destination } = SarSignal.value || {}
  const { cleFlux, estCharge } = FluxSignal.value
  const [data, setData] = useState<Partial<SarTms>>({
    regimeCirculation: [],
  })

  useEffect(() => {
    setData({
      longueur,
      poids,
      regimeCirculation: regimeCirculation || [],
    })
  }, [SarSignal.value])

  const handleChange = (key: string, value: string) => {
    setData({ ...data, [key]: value })
    if (!value) return
    patchSar(FluxSignal.value.id, SarSignal.value.id, {
      ...data,
      [key]: value,
    })
  }

  const handleRegimeChange = (day: string) => (value: boolean) => {
    setData({
      ...data,
      regimeCirculation: value
        ? [...data.regimeCirculation, day]
        : data.regimeCirculation.filter(item => item !== day),
    })

    patchSar(FluxSignal.value.id, SarSignal.value.id, {
      regimeCirculation: value
        ? [...data.regimeCirculation, day]
        : data.regimeCirculation.filter(item => item !== day),
    })
  }

  if (!SarSignal.value) {
    return (
      <div className="loading-wrapper">
        <Loader />
      </div>
    )
  }

  const debouncedHandleChange = debounce(handleChange, 500)

  return (
    <div className="flux-sar">
      <BannerInfo messages={SarSignal.value?.errorMessage || []} />
      <div className="forms">
        <div className="info">
          <h3>
            {
            terms.Pages.Flux.Sar.sarInfoTitle(currentSar.regimeCirculation
              .sort(sortDays)
              .map(day => day.slice(0, 2))
              .join(''))
            }
          </h3>
          <div className="form static">
            <p>
              <span>{terms.Pages.Flux.Sar.form.key}</span>
              <span>{cleFlux}</span>
            </p>
            <p>
              <span>{terms.Pages.Flux.Sar.form.sarOrigin}</span>
              <span>{origine || '-'}</span>
            </p>
            <p>
              <span>{terms.Pages.Flux.Sar.form.state}</span>
              <span>{terms.Pages.Flux[estCharge ? 'loaded' : 'not-loaded']}</span>
            </p>
            <p>
              <span>{terms.Pages.Flux.Sar.form.sarDestination}</span>
              <span>{destination || '-'}</span>
            </p>
          </div>
          <div className="form">
            <TextInput
              label={terms.Pages.Flux.Sar.form.length}
              bindedValue={data?.longueur?.toString()}
              type="number"
              onChange={val => debouncedHandleChange('longueur', val)}
            />
            <TextInput
              label={terms.Pages.Flux.Sar.form.mass}
              bindedValue={data?.poids?.toString()}
              type="number"
              onChange={val => debouncedHandleChange('poids', val)}
            />
          </div>
        </div>
        <div className="circulation">
          <h3>{terms.Pages.Flux.Sar.circulationRegimeTitle}</h3>
          <div className="form">
            {terms.Common.weekdays.map(day => (
              <CheckInput
                key={day}
                label={day}
                defaultValue={data?.regimeCirculation?.includes(day) || false}
                onChange={handleRegimeChange(day)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="buttons" />
    </div>
  )
}
