import { AutocompleteInput } from 'components/inputs'
import {
  SelectedAxeSignal,
  SelectedTubeSignal,
  filtersApi,
  updateAxeFilter,
  updateTubeFilter,
  getMatrix,
  MatrixSignal,
} from 'services'
import { Filter } from 'types/filters'
import terms from 'assets/terms'
import { ModalSignal } from '../ModalWrapper'

export default function FilterModal() {
  const handleAxeChange = (axe: Filter) => {
    updateAxeFilter(axe)
    updateTubeFilter(null)
  }

  const handleTubeChange = (tube: Filter) => {
    updateTubeFilter(tube)
    getMatrix()
    if (tube) {
      MatrixSignal.value = null
      ModalSignal.value = null
    }
  }

  return (
    <>
      <h2>{terms.Modals.Filters.title}</h2>
      <AutocompleteInput<Filter>
        label="Axe de matrice"
        defaultValue={SelectedAxeSignal.value?.libelle}
        defaultSelected={SelectedAxeSignal.value}
        apiEndpoint={filtersApi.AXES}
        resultIdKey="id"
        resultLabelKey="libelle"
        minSearchLength={0}
        onChange={handleAxeChange}
      />
      <AutocompleteInput<Filter>
        label="Tube"
        defaultValue={SelectedTubeSignal.value?.libelle}
        defaultSelected={SelectedTubeSignal.value}
        apiEndpoint={filtersApi.TUBES}
        disabled={!SelectedAxeSignal.value}
        params={{ axe: SelectedAxeSignal.value?.libelle }}
        resultIdKey="id"
        resultLabelKey="libelle"
        minSearchLength={0}
        onChange={handleTubeChange}
      />
    </>
  )
}
