import terms from 'assets/terms'
import { ReactElement } from 'react'
import { SarTms } from 'types'
import { getStatusColor } from 'utils'

import './SarTable.scss'

interface Props {
  data: SarTms[]
}

export default function SarTable({ data }: Props): ReactElement {
  return (
    <div className="sar-table">
      <table>
        <thead>
          <tr>
            <th style={{ gridColumn: 'span 2' }}>{terms.Pages.Fluxs.Table.SAR.sar}</th>
            <th style={{ gridColumn: 'span 2' }} />
            <th style={{ gridColumn: 'span 2' }}>{terms.Pages.Fluxs.Table.SAR.length}</th>
            <th style={{ gridColumn: 'span 2' }}>{terms.Pages.Fluxs.Table.SAR.weight}</th>
            <th style={{ gridColumn: 'span 3' }}>{terms.Pages.Fluxs.Table.SAR.lotsNumber}</th>
            <th style={{ gridColumn: 'span 3' }}>{terms.Pages.Fluxs.Table.SAR.origin}</th>
            <th style={{ gridColumn: 'span 3' }}>{terms.Pages.Fluxs.Table.SAR.destination}</th>
            <th style={{ gridColumn: 'span 3' }}>{terms.Pages.Fluxs.Table.SAR.regime}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.id}>
              <td style={{ gridColumn: 'span 2' }}>{`${terms.Pages.Fluxs.Table.SAR.sar} ${index + 1}`}</td>
              <td style={{ gridColumn: 'span 2' }}>
                <div className="status" style={{ background: getStatusColor(item.statutExport).chip }} />
              </td>
              <td style={{ gridColumn: 'span 2' }}>{`${item.longueur}m`}</td>
              <td style={{ gridColumn: 'span 2' }}>{`${item.poids}t`}</td>
              <td style={{ gridColumn: 'span 3' }}>{item.nombreLots}</td>
              <td style={{ gridColumn: 'span 3' }}>{item.origine || '-'}</td>
              <td style={{ gridColumn: 'span 3' }}>{item.destination || '-'}</td>
              <td style={{ gridColumn: 'span 3' }} className="circulation">
                {item.regimeCirculation.map(day => (
                  <span key={day}>
                    {day}
                  </span>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
