import terms from 'assets/terms'
import { ReactElement, useState } from 'react'
import {
  FluxSignal, getFlux, getSar, getSars, SarSignal, SarsSignal, getLots, getLot, LotsSignal,
  getTrames,
} from 'services'
import { FluxElement, LotType, SarTms } from 'types'
import { getStatusColor, sortDays } from 'utils'
import { ReactComponent as Home } from 'assets/icons/home.svg'
import AddIcon from '@mui/icons-material/Add'
import Accordion from 'components/accordion/Accordion'

import './FluxSideMenu.scss'

interface Props {
  setElementSelected: (element: FluxElement) => void
  elementSelected: FluxElement
}

export default function FluxSideMenu({ setElementSelected, elementSelected }: Props): ReactElement {
  const [lotSelected, setLotSelected] = useState<string | null>(null)
  const [sarSelected, setSarSelected] = useState<string | null>(null)
  const id = FluxSignal.value?.id
  const handleSelect = <T, >(type: FluxElement, element?: T) => {
    switch (type) {
      case FluxElement.SAR:
        setElementSelected(FluxElement.SAR)
        setSarSelected((element as SarTms).id)
        getSar(id, (element as SarTms).id)
        getLots(id, (element as SarTms).id)
        break
      case FluxElement.LOT:
        setElementSelected(FluxElement.LOT)
        setLotSelected((element as LotType).id)
        getLot(id, SarSignal.value?.id, (element as LotType).id)
        getTrames(id, SarSignal.value?.id, (element as LotType).id)
        break
      default:
        FluxSignal.value = null
        getFlux(id)
        getSars(id)
        setElementSelected(FluxElement.HOME)
        break
    }
  }

  return (
    <div className="flux-side-menu">
      <div className={elementSelected === FluxElement.HOME ? 'selected' : ''}>
        <button
          type="button"
          className="button"
          onClick={() => handleSelect<boolean>(FluxElement.HOME)}
        >
          <span>
            {terms.Pages.Flux.title}
          </span>
          <Home />
        </button>
      </div>
      <div className="accordions">
        {
          SarsSignal.value.map(sar => (
            <Accordion
              key={sar.id}
              title={(
                <div className="title">
                  <div>
                    {`${terms.Pages.Flux.Sar.title} ${sar.regimeCirculation
                      .sort(sortDays)
                      .map(day => day.slice(0, 2))
                      .join('')}`}
                  </div>
                  <span
                    style={{ backgroundColor: getStatusColor(sar.statutExport).chip }}
                  />
                </div>
              )}
              handleClick={() => handleSelect<SarTms>(FluxElement.SAR, sar)}
              defaultExpanded={false}
              removeExpand={sarSelected !== sar.id}
            >
              <div className="accodion-content">
                <div className="lot-button">
                  {LotsSignal.value.map((lot, ind) => (
                    <div key={lot.id} className={lotSelected === lot.id ? 'selected' : ''}>
                      <button
                        type="button"
                        onClick={() => handleSelect<LotType>(FluxElement.LOT, lot)}
                        className="button"
                      >
                        {`${terms.Pages.Flux.Lot.title} ${ind + 1}`}
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={() => { /* to implement */ }}
                    className="add"
                  >
                    <AddIcon className="icon" />
                  </button>
                </div>
              </div>
            </Accordion>
          ))
        }
        <button
          type="button"
          onClick={() => { /* to implement */ }}
          className="button"
        >
          <span>
            {terms.Pages.Flux.Sar.add}
          </span>
          <AddIcon className="icon" />
        </button>
      </div>
    </div>
  )
}
