import terms from 'assets/terms'
import { ReactElement, useState } from 'react'
import { TramesSignal, updateSectionPlanification } from 'services'
import { v4 as uuidV4 } from 'uuid'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AddIcon from '@mui/icons-material/Add'
import Trame from './Trame'

import './Trames.scss'

export default function Trames(): ReactElement {
  const trames = TramesSignal.value
  const [selectedEdit, setSelectedEdit] = useState<string>('')

  const onAddTrame = () => {
    updateSectionPlanification([])
    TramesSignal.value.push({
      id: 'new',
      lotsTronconsPlanifies: [{
        id: 'new',
        rangLot: 0,
        lot: null,
        troncon: {
          id: 'new',
          arretArrivee: null,
          arretDepart: null,
          mission: null,
          capaciteLongueur: 0,
          capacitePoids: 0,
          rang: 0,
          remplissage: 0,
          sensReleve: '',
          joursArrivee: [],
          joursDepart: [],
          tube: null,
        },
        timeToWait: '',
        jourCirculation: '',
        jourCirculationArrivee: '',
      }],
      rangTroncon: 0,
    })
    setSelectedEdit('new')
  }

  return (
    <div className="trames">
      <h3>{terms.Pages.Flux.Lot.Trame.title}</h3>
      <div className="trames-wrapper">
        {
          trames?.map(trame => (
            <div key={uuidV4}>
              <Trame selectedEdit={selectedEdit} setSelectedEdit={setSelectedEdit} trame={trame} />
              {
                trame.lotsTronconsPlanifies[0]?.timeToWait && (
                  <div className="time-interval">
                    {
                      trame.lotsTronconsPlanifies?.map(item => (
                        <div key={uuidV4()}>
                          <AccessTimeIcon className="icon" />
                          <span>{item?.timeToWait?.slice(0, -3)}</span>
                        </div>
                      ))
                    }
                  </div>
                )
              }
            </div>
          ))
        }
        <button
          type="button"
          className="add"
          onClick={onAddTrame}
        >
          <AddIcon />
          <span>{terms.Pages.Flux.Lot.Trame.addSection}</span>
        </button>
      </div>
    </div>
  )
}
