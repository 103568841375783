import { ExportStatus } from 'types'

/**
 * Get the color of the status
 * @param status: ExportStatus
 * @returns object { background: string, chip: string }
*/
export const getStatusColor = (status: ExportStatus): { background: string, chip: string } => {
  switch (status) {
    case ExportStatus.PENDING:
      return {
        background: '#256EFF26',
        chip: '#256EFF',
      }
    case ExportStatus.SUCCESS:
      return {
        background: '#DDECD4',
        chip: '#43CF7B',
      }
    case ExportStatus.FAILURE:
      return {
        background: '#FF000026',
        chip: '#DA4238',
      }
    default:
      return {
        background: '#FFFFFF',
        chip: '#FFFFFF',
      }
  }
}
