import { ReactElement, useEffect, useState } from 'react'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import dayjs from 'dayjs'

import '../wrapper.scss'
import './TimeInput.scss'

interface Props {
  required?: boolean
  label?: string
  defaultValue: string
  shouldReset?: boolean
  onChange: (value: string) => void
}

/**
 * Time input component
 * @param required - Whether the input is required
 * @param label - The label of the input
 * @param defaultValue - The default value of the input
 * @param shouldReset - Whether the input should reset
 * @param onChange - The function to call when the input changes
 *
 * @returns The TimeInput component
 */
export default function TimeInput({
  required,
  label,
  defaultValue,
  shouldReset,
  onChange,
}: Props): ReactElement {
  const [value, setValue] = useState<string | null>(defaultValue)

  useEffect(() => {
    if (shouldReset) {
      onChange('')
    }
  }, [shouldReset])

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleChange = (time: dayjs.Dayjs) => {
    if (!time) {
      setValue(null)
      onChange('')
      return
    }
    setValue(time.format('HH:mm:00'))
    onChange(time.format('HH:mm:00'))
  }

  return (
    <div className={`time input-wrapper${(required && !value) ? ' error' : ''}`}>
      {label && <label htmlFor={label}>{label}</label>}
      <TimePicker
        views={['hours', 'minutes']}
        format="HH:mm"
        value={value ? dayjs(value, 'HH:mm') : null}
        onChange={handleChange}
        ampm={false}
      />
    </div>
  )
}
