import { ReactElement } from 'react'
import { ReactComponent as Edit } from 'assets/icons/editCircle.svg'
import { ReactComponent as Delete } from 'assets/icons/deleteCircle.svg'
import { ReactComponent as Validate } from 'assets/icons/validateCircle.svg'

import '../Trames.scss'

interface Props {
  onEdit: boolean;
  setOnEdit: (value: boolean) => void;
  handlePatch: () => void;
  onOpenDelete: () => void;
  disabled?: boolean;
}
export default function TrameActions({
  onEdit, setOnEdit, handlePatch, disabled = false, onOpenDelete,
}: Props): ReactElement {
  return (
    <div className="actions">
      {
        onEdit ? (
          <div>
            <button
              type="button"
              onClick={handlePatch}
              disabled={disabled}
            >
              <Validate />
            </button>
          </div>
        ) : (
          <div>
            <button type="button" onClick={() => setOnEdit(true)}>
              <Edit />
            </button>
            <button
              type="button"
              onClick={onOpenDelete}
            >
              <Delete />
            </button>
          </div>
        )
      }
    </div>
  )
}
