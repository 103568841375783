import terms from 'assets/terms'
import { get, patch } from '@osrdata/app_core/dist/requests'
import { signal } from '@preact/signals-react'
import { SelectedClientsFluxSignal, SelectedSearchFluxSignal } from 'services'
import { FluxType, FluxsPaginate } from 'types'
import { catchable } from 'utils'
import { ToastLevel, ToastSignal } from 'components'

//* --------------- List API URL flux ---------------
export const fluxsApi = {
  FLUXS: '/apt/flux',
  FLUX: (id: string) => `/apt/flux/${id}`,
  PUBLISH: (id: string) => `/apt/flux/${id}/publish`,
}

//* --------------- Signal to store flux ---------------
export const FluxsSignal = signal<FluxsPaginate>(null)
export const FluxSignal = signal<FluxType>(null)

//* --------------- Update fluxs to the signal ---------------
/**
 * Update fluxs to the signal
 *
 * @param fluxs - list of fluxs
*/
export const updateFluxs = (fluxs: FluxsPaginate) => {
  FluxsSignal.value = fluxs
}

/**
 * Update Flux to the signal
 * @param flux - flux
*/
export const updateFlux = (flux: FluxType) => {
  FluxSignal.value = flux
}

//* --------------- API flux ---------------

/**
 * Fetches a flux by its id
 * @param id - flux id
 * @returns FluxType
*/
export const getFlux = (id: string) => catchable(async () => {
  FluxSignal.value = null
  const response = await get<FluxType>(fluxsApi.FLUX(id))
  updateFlux(response)
})

/**
 * Fetches all fluxs
 * @returns FluxsPaginate
*/
export const getFluxs = (url = '') => catchable(async () => {
  const clients = SelectedClientsFluxSignal.value
  const search = SelectedSearchFluxSignal.value
  if (url) {
    const response = await get<FluxsPaginate>(url)
    updateFluxs({
      ...response,
      results: [
        ...FluxsSignal.value?.results || [],
        ...response.results,
      ],
    })
    return
  }
  const response = await get<FluxsPaginate>(fluxsApi.FLUXS, {
    page_size: 5,
    ...(search && { search }),
    ...(clients.length && { clients: clients?.map(client => client.libelle).join(',') }),
  })
  updateFluxs(response)
})

/**
 * Patch a flux
 * @param id - flux id
 * @param data - flux data
 * @returns FluxType
*/
export const patchFlux = (id: string, data: Partial<FluxType>) => catchable(async () => {
  const response = await patch<FluxType>(fluxsApi.FLUX(id), data)
  updateFlux(response)
  ToastSignal.value = { message: terms.Pages.Flux.updateSuccess, severity: ToastLevel.SUCCESS }
  return response
}, true)
