import { ReactElement } from 'react'
import terms from 'assets/terms'
import { filtersApi } from 'services'
import { Place } from 'types'
import { AutocompleteInput, SelectInput } from 'components'
import TimeInput from 'components/inputs/time/TimeInput'
import { weeklyDays } from 'utils'
import { debounce } from 'lodash'

interface Props {
  required?: boolean
  type: 'arretArrivee' | 'arretDepart'
  autocompleteValue: Place
  timeValue: string
  selectValue: string
  handleChange: <T>(key: string, value: T, secondKey?: string) => void
  days: string[]
}

export default function StationForm({
  required,
  type,
  autocompleteValue,
  timeValue,
  selectValue,
  handleChange,
  days,
}: Props): ReactElement {
  const debouncedHandleChange = debounce(handleChange, 500)
  return (
    <div>
      <h4>
        {terms.Pages.Mission.Form.Section.stationLocation(type)}
      </h4>
      <div className="form">
        <AutocompleteInput<Place>
          label={terms.Pages.Mission.Form.Section.name}
          apiEndpoint={filtersApi.PLACES}
          defaultValue={autocompleteValue?.ngramme || ''}
          resultIdKey="id"
          resultLabelKey="ngramme"
          onChange={val => handleChange(type, val, 'lieu')}
          defaultSelected={autocompleteValue}
          required={required}
          minSearchLength={0}

        />
        <TimeInput
          label={terms.Pages.Mission.Form.Section.time(type)}
          defaultValue={timeValue}
          onChange={val => debouncedHandleChange(
            type,
            `${val}:00`,
            type === 'arretDepart' ? 'heureDepart' : 'heureArrivee',
          )}
          required={required}
        />
        <SelectInput
          label={terms.Pages.Mission.Form.Section.midnight(type)}
          defaultValue={selectValue}
          onChange={val => handleChange<number>(
            type,
            parseInt(val as string, 10),
            type === 'arretDepart' ? 'decalageDepart' : 'decalageArrivee',
          )}
          options={weeklyDays.map((_, index) => ({ label: index.toString(), value: index.toString() }))}
        />
        <div className="days">
          {
            days?.map(day => <span key={day}>{day}</span>)
          }
        </div>
      </div>
    </div>
  )
}
