import terms from 'assets/terms'
import { ReactElement, useEffect, useState } from 'react'
import { MissionSignal, patchMission, filtersApi, verifyMissionId } from 'services'
import { Bfr, Egs, Mission } from 'types'
import {
  AutocompleteInput, Button, ButtonStyle, CheckInput, Loader, TextInput,
} from 'components'
import BannerInfo from 'components/banner/BannerInfo'
import { debounce } from 'lodash'

import './MissionHome.scss'

export default function MissionHome(): ReactElement {
  const mission = MissionSignal.value
  const [currentMission, setCurrentMission] = useState<Mission>(mission)
  const [outBfr, setOutBfr] = useState<boolean>(false)

  useEffect(() => {
    if (!mission) return
    setCurrentMission(mission)
  }, [mission])

  if (!mission) {
    return (
      <div className="loading-wrapper">
        <Loader />
      </div>
    )
  }

  function handleChange<T>(value: T, key: string) {
    switch (key) {
      case 'missionId':
        setCurrentMission({
          ...currentMission,
          missionId: value as string,
        })
        if (verifyMissionId(value as string)) {
          const prevUpdatedId = mission.missionId
          patchMission({
            id: currentMission.id,
            missionId: value as string,
          }).then(() => {
            if (prevUpdatedId !== currentMission.missionId) {
              window.location.href = `/missions/${currentMission.missionId}`
            }
          })
        }
        break
      default:
        setCurrentMission({
          ...currentMission,
          [key]: value,
        })
        patchMission({
          id: currentMission.id,
          [key]: value,
        })
        break
    }
  }

  useEffect(() => {
    setOutBfr(currentMission.codeBfr === null || false)
  }, [MissionSignal.value])

  const debouncedHandleChange = debounce(handleChange, 500)

  return (
    <div className="mission-home">
      <BannerInfo messages={currentMission.errorMessage} />
      <h3>
        {terms.Pages.Mission.Form.Edit.title}
      </h3>
      <div className="form">
        <TextInput
          label={terms.Pages.Mission.Form.Edit.mission}
          bindedValue={currentMission.missionId}
          onChange={value => debouncedHandleChange(value, 'missionId')}
          clearable
          type="string"
          required
          errorMessage={!verifyMissionId(currentMission.missionId) ? terms.Pages.Mission.Form.Errors.missionLength : ''}
        />
        <CheckInput
          label={terms.Pages.Mission.Form.Edit.missionSecurity}
          defaultValue={currentMission.missionDeSecurite}
          onChange={() => handleChange<boolean>(!currentMission.missionDeSecurite, 'missionDeSecurite')}
        />
        <AutocompleteInput<Egs>
          label={terms.Pages.Mission.Form.Edit.egs}
          apiEndpoint={filtersApi.EGS}
          defaultValue={currentMission.egs ? `${currentMission.egs?.lrEgs} - ${currentMission.egs?.libelle}` : ''}
          defaultSelected={currentMission.egs}
          resultIdKey="id"
          resultLabelKey="libelle"
          resultLabelKeyOptional="lrEgs"
          minSearchLength={0}
          onChange={value => handleChange<Egs>(value as Egs, 'egs')}
          required
        />
        <CheckInput
          label={terms.Pages.Mission.Form.Edit.outBfr}
          defaultValue={outBfr}
          onChange={() => setOutBfr(!outBfr)}
        />
        <AutocompleteInput<Bfr>
          label={terms.Pages.Mission.Form.Edit.bfrCode}
          defaultValue={currentMission.codeBfr
            ? `${currentMission.codeBfr?.numero} - ${currentMission.codeBfr?.libelle}`
            : ''}
          defaultSelected={currentMission.codeBfr}
          apiEndpoint={filtersApi.BFR}
          resultIdKey="id"
          resultLabelKey="libelle"
          resultLabelKeyOptional="numero"
          params={{ egs: currentMission.egs?.libelle || '' }}
          minSearchLength={0}
          onChange={value => handleChange<Bfr>(value as Bfr, 'codeBfr')}
          disabled={!!outBfr}
          required={!outBfr}
        />
      </div>
      <div className="buttons">
        <Button
          text={terms.Common.delete}
          onClick={() => { /* To implement */ }}
          style={ButtonStyle.delete}
        />
      </div>
    </div>
  )
}
