import { ReactElement } from 'react'
import InfoIcon from '@mui/icons-material/ErrorOutline'

import './BannerInfo.scss'

interface Props {
  messages: {
    message: string
    httpCode: number
  }[]
}

export default function BannerInfo({ messages }: Props): ReactElement {
  return (
    <div className={`info-banner${messages.length ? ' active' : ''}`}>
      {
        messages.map(item => (
          <div key={item.message} className="message">
            <InfoIcon />
            <span>
              {item.message}
            </span>
          </div>
        ))
      }
    </div>
  )
}
