/**
 * Save to local storage or remove if value is null
 *
 * @param key value to save
 * @param value value to save
 */
export function saveToLocal<T>(key: string, value: T) {
  if (!value) {
    localStorage.removeItem(key)
    return
  }
  localStorage.setItem(key, JSON.stringify(value))
}

/**
 * Save to session storage or remove if value is null
 *
 * @param key key to save
 * @param value value to save
 */
export function saveToSession(key: string, value: string | null) {
  if (!value) {
    sessionStorage.removeItem(key)
    return
  }
  sessionStorage.setItem(key, value)
}
