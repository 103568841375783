import moment from 'moment'

/**
 * Format HH:mm:ss to HH:mm
 * @param hour: string
 * @returns string
 */
export const formatHour = (hour: string): string => moment(hour, 'HH:mm:ss').format('HH:mm')

export const weeklyDays = [
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
  'Dimanche',
]

export const sortDays = (a: string, b: string) => weeklyDays.indexOf(a) - weeklyDays.indexOf(b)
