import { deleteRequest, get, patch, post } from '@osrdata/app_core/dist/requests'
import { signal } from '@preact/signals-react'
import terms from 'assets/terms'
import { ToastLevel, ToastSignal } from 'components'
import { SelectedTubeSignal } from 'services'
import { Section, SectionDetail } from 'types'
import { catchable } from 'utils'

//* --------------- List API URL sections ---------------
export const sectionsApi = {
  SECTIONS: (id: string) => `/apt/missions/${id}/troncons`,
  VARIANT_SECTIONS: (id: string, varId: string) => `/apt/missions/${id}/variantes/${varId}/troncons`,
  VARIANT_SECTION: (id: string, varId: string, troId: string) => (
    `/apt/missions/${id}/variantes/${varId}/troncons/${troId}`
  ),
}

//* --------------- Signal to store sections ---------------
export const SectionsSignal = signal<Section[]>([])
export const VariantSectionsSignal = signal<SectionDetail[]>([])
export const VariantSectionSignal = signal<SectionDetail>(null)

//* --------------- Update sections to the signal ---------------
/**
 * Update variant sections to the signal
 * @param sections - list of sections
*/
export const updateVariantSections = (sections: SectionDetail[]) => {
  VariantSectionsSignal.value = sections
}

/**
 * Update variant section to the signal
 * @param section - section
*/
export const updateVariantSection = (section: SectionDetail) => {
  VariantSectionSignal.value = section
}

/**
 * Update sections to the signal and sort them by mission id
 *
 * @param sections - list of sections
 */
export const updateSections = (sections: Section[]) => {
  SectionsSignal.value = [
    ...SectionsSignal.value.filter(section => !sections.find(s => s.id === section.id)),
    ...sections,
  ]
}

//* --------------- API sections ---------------
/**
 * Fetch Section by mission id
 *
 * @param id - mission id
*/
export const getSectionByMission = (id: string) => SectionsSignal.value.filter(section => section.missionId === id)

/**
 * Fetches a section by its mission id
 *
 * @param id - mission id
 */
export const getSection = (id: string) => catchable(async () => {
  const response = await get<Section[]>(
    sectionsApi.SECTIONS(id),
    { tube: SelectedTubeSignal.value.libelle },
  )
  updateSections(response)
})

/**
 * Fetches all sections for a variant
 * @param id: string  Mission id
 * @param varId: string Variant id
*/
export const getVariantSections = (id: string, varId: string) => catchable(async () => {
  const response = await get<SectionDetail[]>(sectionsApi.VARIANT_SECTIONS(id, varId))
  updateVariantSections(response)
})

/**
 * Fetches a section by its id
 * @param id: string  Mission id
 * @param varId: string Variant id
 * @param troId: string Section id
*/
export const getVariantSection = (id: string, varId: string, secId: string) => catchable(async () => {
  const response = await get<SectionDetail>(sectionsApi.VARIANT_SECTION(id, varId, secId))
  updateVariantSection(response)
})

/**
 * Patch a section
 * @param id: string  Mission id
 * @param varId: string Variant id
 * @param section:Partial <SectionDetail>
 */
export const patchVariantSection = (
  id: string,
  varId: string,
  section: Partial<SectionDetail>,
) => catchable(async () => {
  const reponse = await patch<SectionDetail>(sectionsApi.VARIANT_SECTION(id, varId, section.id), section)

  if (reponse) {
    ToastSignal.value = { message: terms.Pages.Mission.Form.Section.successUpdate, severity: ToastLevel.SUCCESS }
    updateVariantSection(reponse)
    const newVariantSections = VariantSectionsSignal.value.map(s => (s.id === reponse.id ? reponse : s))
    updateVariantSections(newVariantSections)
  }
}, true)

/**
 * Post a section
 * @param id: string  Mission id
 * @param varId: string Variant id
 * @param section:Partial <SectionDetail>
*/
export const postVariantSection = (
  id: string,
  varId: string,
  section: Partial<SectionDetail>,
) => catchable(async () => {
  VariantSectionSignal.value = null
  const reponse = await post<SectionDetail>(sectionsApi.VARIANT_SECTIONS(id, varId), section)

  if (reponse) {
    ToastSignal.value = { message: terms.Pages.Mission.Form.Section.successCreate, severity: ToastLevel.SUCCESS }
    updateVariantSection(reponse)
    updateVariantSections([...VariantSectionsSignal.value, reponse])
  }
}, true)

/**
 * Delete a section
 * @param id: string  Mission id
 * @param varId: string Variant id
 * @param secId: string Section id
*/
export const deleteVariantSection = (
  id: string,
  varId: string,
  secId: string,
) => catchable(async () => {
  await deleteRequest(sectionsApi.VARIANT_SECTION(id, varId, secId))
  updateVariantSections(VariantSectionsSignal.value.filter(s => s.id !== secId))
  ToastSignal.value = { message: terms.Pages.Mission.Form.Section.successDelete, severity: ToastLevel.SUCCESS }
}, true)
