import { ReactElement, useEffect, useState } from 'react'
import { v4 as uuidV4 } from 'uuid'

import './RadioInput.scss'
import '../wrapper.scss'

type Props = {
  label: string
  defaultValue: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
}

/**
 * Radio input component
 *
 * @param label Label of the radio input
 * @param defaultValue Default value of the radio input
 * @param onChange Function to call when the radio input is changed
 * @param disabled If the radio input is disabled
 * @returns
 */
export default function RadioInput({
  label,
  defaultValue,
  onChange,
  disabled = false,
}: Props): ReactElement {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleChange = () => {
    onChange(!value)
    setValue(!value)
  }

  const generateId = uuidV4()

  return (
    <div className="radio">
      <input
        id={generateId}
        type="radio"
        checked={value}
        onChange={handleChange}
        disabled={disabled}
      />
      <label htmlFor={generateId}>{label}</label>
    </div>
  )
}
