import { ReactElement, useEffect, useState } from 'react'
import { postClient } from 'services'
import { Filter } from 'types'
import { Button, ButtonStyle, ModalSignal, TextInput } from 'components'
import terms from 'assets/terms'

import './AddClientModal.scss'
import { ButtonSize } from 'components/button/Button'

interface Props {
  handleClientAdded: (client: Filter) => void
  defaultValue?: string
}
export default function AddClientModal({ handleClientAdded, defaultValue }: Props): ReactElement {
  const [newClient, setNewClient] = useState<string>(defaultValue || '')

  useEffect(() => {
    setNewClient(defaultValue || '')
  }, [defaultValue])

  const handleClose = () => {
    ModalSignal.value = null
  }

  const handleAddClient = async () => {
    const response = await postClient(newClient) as Filter
    if (response?.id) {
      handleClientAdded(response)
      handleClose()
    }
  }

  return (
    <div className="add-client-modal">
      <TextInput
        label={terms.Pages.Flux.modalAddClient}
        onChange={val => setNewClient(val)}
      />
      <div className="flex-center">
        <Button
          text={terms.Common.cancel}
          style={ButtonStyle.borderLess}
          size={ButtonSize.medium}
          onClick={handleClose}
        />
        <Button
          text={terms.Common.add}
          onClick={handleAddClient}
          size={ButtonSize.medium}
        />
      </div>
    </div>
  )
}
