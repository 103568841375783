import terms from 'assets/terms'
import { ReactElement } from 'react'
import CircleDownIcon from '@mui/icons-material/ExpandCircleDownOutlined'
import { SelectedClientsFluxSignal, SelectedSearchFluxSignal } from 'services'
import { FilterFluxsModal, ModalSignal } from 'components'

import './FluxsHeader.scss'

export default function FluxsHeader(): ReactElement {
  const clientsSelected = SelectedClientsFluxSignal.value
  const searchSelected = SelectedSearchFluxSignal.value
  const handleClick = () => {
    ModalSignal.value = <FilterFluxsModal />
  }
  return (
    <div className="fluxs-header">
      <button
        type="button"
        className="actions"
        onClick={handleClick}
      >
        <div className="filter">
          {terms.Pages.Fluxs.FilterModal.title}
        </div>
        <div className="filters-info">
          <div className="info">
            <div>
              {terms.Pages.Fluxs.Header.client}
            </div>
            {
              clientsSelected?.map(client => (
                <span className="chip" key={client.id}>
                  {client.libelle}
                </span>
              ))
            }
          </div>
          <div className="info">
            <div>
              {terms.Common.searching}
            </div>
            <span>
              {searchSelected}
            </span>
          </div>
        </div>
        <CircleDownIcon />
      </button>
    </div>
  )
}
