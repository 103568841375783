import terms from 'assets/terms'
import { ReactElement, useEffect, useState } from 'react'
import {
  MissionSignal, VariantSignal, VariantStepSignal, VariantStepsSignal, deleteVariantStep, filtersApi,
  patchVariantStep,
  validFormatNumSillon,
} from 'services'
import { Filter, OnOpenDelete, Step } from 'types'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  AutocompleteInput, Button, ButtonStyle, CheckInput, Loader, SelectInput, TextInput,
} from 'components'
import Timeline from 'components/diagram/Timeline'
import { debounce } from 'lodash'
import StationForm from '../section/StationForm'

import './MissionStep.scss'

export default function MissionStep({ onOpenDelete }: OnOpenDelete): ReactElement {
  const mission = MissionSignal.value
  const variant = VariantSignal.value
  const step = VariantStepSignal.value
  const steps = VariantStepsSignal.value
  const [currentStep, setCurrentStep] = useState<Step>(step)

  useEffect(() => {
    if (!step) return
    setCurrentStep(step)
  }, [step])

  if ((!variant || !step || !mission || !steps || !currentStep)) {
    return (
      <div className="loading-wrapper">
        <Loader />
      </div>
    )
  }

  const title = `
    ${terms.Pages.Mission.SideMenu.variant}
    ${variant.jourHebdo.map(day => day.slice(0, 2)).join('')} - 
    ${terms.Pages.Mission.Form.ConvoyingStep.title(false)} 
    ${steps.findIndex(item => item.id === step.id) + 1}/${steps.length}
  `

  const handleChange = <T, >(key: string, value: T, secondKey?: string) => {
    switch (key) {
      case 'numeroSillon':
        if (value === currentStep.numeroSillon) return
        setCurrentStep({
          ...currentStep,
          numeroSillon: value as string,
        })
        if (validFormatNumSillon(value as string)) {
          patchVariantStep(
            mission.id,
            variant.id,
            {
              ...currentStep,
              numeroSillon: value as string,
            },
          )
        }
        break
      case 'cco':
        setCurrentStep({
          ...currentStep,
          cco: value as Filter,
        })
        patchVariantStep(
          mission.id,
          variant.id,
          {
            ...currentStep,
            cco: value as Filter,
          },
        )
        break
      case 'gesco':
        setCurrentStep({
          ...currentStep,
          gesco: value as Filter,
        })
        patchVariantStep(
          mission.id,
          variant.id,
          {
            ...currentStep,
            gesco: value as Filter,
          },
        )
        break
      case 'indiceComposition':
        setCurrentStep({
          ...currentStep,
          indiceComposition: value as Filter,
        })
        patchVariantStep(
          mission.id,
          variant.id,
          {
            ...currentStep,
            indiceComposition: value as Filter,
          },
        )
        break
      default:
        if (value === undefined || value === null) return
        setCurrentStep({
          ...currentStep,
          [key]: secondKey ? {
            ...currentStep[key],
            [secondKey]: value,
          } : value,
        })
        patchVariantStep(
          mission.id,
          variant.id,
          {
            ...currentStep,
            [key]: secondKey ? {
              ...currentStep[key],
              [secondKey]: value,
            } : value,
          },
        )
    }
  }

  const debouncedHandleChange = debounce(handleChange, 500)

  return (
    <div className="variant-step">
      <div className="header">
        <Timeline
          selectedId={step.id}
          data={steps}
          type="step"
        />
      </div>
      <div className="form-container">
        <h3>
          {title}
        </h3>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="forms">
            <div>
              <h4>
                {terms.Pages.Mission.Form.Section.generalInfo}
              </h4>
              <div className="form">
                <CheckInput
                  label={terms.Pages.Mission.Form.ConvoyingStep.footHight}
                  defaultValue={currentStep?.estHautLePied || false}
                  onChange={val => handleChange('estHautLePied', val)}
                />
                <TextInput
                  label="Sillon"
                  type="number"
                  bindedValue={currentStep.numeroSillon || ''}
                  onChange={val => debouncedHandleChange('numeroSillon', val)}
                  required
                  errorMessage={
                    !validFormatNumSillon(currentStep.numeroSillon) ? terms.Pages.Mission.Form.Errors.numSillon : ''
                  }
                />
                <TextInput
                  label={terms.Pages.Mission.Form.ConvoyingStep.distance}
                  type="number"
                  bindedValue={currentStep.distance?.toString() || '0'}
                  onChange={val => debouncedHandleChange('distance', parseInt(val, 10))}
                  required
                />
                <SelectInput
                  label={terms.Pages.Mission.Form.ConvoyingStep.disconnectionDirection}
                  defaultValue={currentStep.sensDebranchement || 'AZ'}
                  onChange={val => handleChange('sensDebranchement', val)}
                  options={[
                    { label: 'AZ', value: 'AZ' },
                    { label: 'ZA', value: 'ZA' },
                  ]}
                />
                <AutocompleteInput<Filter>
                  label={terms.Pages.Mission.Form.ConvoyingStep.composition}
                  apiEndpoint={filtersApi.COMPOSITION}
                  defaultValue={currentStep.indiceComposition?.libelle || ''}
                  defaultSelected={currentStep.indiceComposition}
                  resultIdKey="id"
                  resultLabelKey="libelle"
                  onChange={val => handleChange('indiceComposition', val)}
                  minSearchLength={0}
                />
                <AutocompleteInput<Filter>
                  label={terms.Pages.Mission.Form.ConvoyingStep.cco}
                  apiEndpoint={filtersApi.CCO}
                  defaultValue={currentStep.cco?.libelle || ''}
                  defaultSelected={currentStep.cco}
                  resultIdKey="id"
                  resultLabelKey="libelle"
                  onChange={val => handleChange('cco', val)}
                  minSearchLength={0}
                />
                <AutocompleteInput<Filter>
                  label={terms.Pages.Mission.Form.ConvoyingStep.gesco}
                  apiEndpoint={filtersApi.GESCO}
                  defaultValue={currentStep.gesco?.libelle || ''}
                  defaultSelected={currentStep.gesco}
                  params={{ cco: currentStep.cco?.libelle || '' }}
                  resultIdKey="id"
                  resultLabelKey="libelle"
                  onChange={val => handleChange('gesco', val)}
                  minSearchLength={0}
                />
              </div>
            </div>
            <StationForm
              type="arretDepart"
              autocompleteValue={currentStep.arretDepart?.lieu}
              timeValue={currentStep.arretDepart?.heureDepart || '00:00'}
              selectValue={currentStep.arretDepart?.decalageDepart?.toString() || '0'}
              handleChange={(key, val, secondKey) => handleChange(key, val, secondKey)}
              days={currentStep.joursDepart || []}
              required
            />
            <StationForm
              type="arretArrivee"
              autocompleteValue={currentStep.arretArrivee?.lieu}
              timeValue={currentStep.arretArrivee?.heureArrivee || '00:00'}
              selectValue={currentStep.arretArrivee?.decalageArrivee?.toString() || '0'}
              handleChange={(key, val, secondKey) => handleChange(key, val, secondKey)}
              days={currentStep.joursArrivee || []}
              required
            />
          </div>
        </LocalizationProvider>
        <div className="buttons">
          <Button
            text={terms.Common.delete}
            onClick={() => onOpenDelete(
              terms.Pages.Mission.Form.ConvoyingStep.Modal.deleteText,
              () => deleteVariantStep(mission.id, variant.id, currentStep.id),
            )}
            style={ButtonStyle.delete}
          />
        </div>
      </div>
    </div>
  )
}
