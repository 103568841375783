import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { get } from '@osrdata/app_core/dist/requests'
import Loader from 'components/loaderComponent/Loader'
import { debounce } from 'lodash'
import { FocusEvent, useEffect, useRef, useState } from 'react'
import { useClickOutside } from 'utils'
import { v4 as uuidV4 } from 'uuid'
import TextInput from '../text/TextInput'
import { InputSize } from '..'

import '../wrapper.scss'
import './AutocompleteInput.scss'

type Props<T> = {
  required?: boolean
  disabled?: boolean
  label?: string
  apiEndpoint: string
  params?: Record<string, string>
  defaultValue?: string | string[]
  defaultSelected?: T | T[]
  resultIdKey?: string
  resultLabelKey?: string
  resultLabelKeyOptional?: string
  minSearchLength?: number
  onChange: (value: T | T[]) => void
  errorMessage?: string
  shouldReset?: boolean
  placeholder?: string
  multiple?: boolean
  size?: InputSize
  paginateResponse?: boolean
}

/**
 * AutocompleteInput component
 * @param required - Whether the input is required
 * @param disabled - Whether the input is disabled
 * @param label - The label of the input
 * @param apiEndpoint - The endpoint to call to get the results
 * @param params - The parameters to send to the endpoint
 * @param defaultValue - The default value of the input
 * @param defaultSelected - The selected value
 * @param resultIdKey - The key of the result to use as id
 * @param resultLabelKey - The key of the result to use as label
 * @param resultLabelKeyOptional - The second key to construct the label of the result
 * @param minSearchLength - The minimum length of the search value to trigger the search
 * @param onChange - The function to call when a result is selected
 * @param errorMessage - The error message to display
 * @param shouldReset - Whether the input should reset
 * @param placeholder - The placeholder of the input
 * @param multiple - Whether the input is multiple
 * @param size - The size of the input
 * @param paginateResponse - Whether the response is paginated
 *
 * @returns The AutocompleteInput component
 */
export default function AutocompleteInput<T extends {id: string}>({
  required = false,
  disabled,
  label,
  apiEndpoint,
  params,
  defaultValue,
  defaultSelected,
  resultIdKey,
  resultLabelKey,
  resultLabelKeyOptional = '',
  minSearchLength = 1,
  onChange,
  errorMessage = '',
  shouldReset,
  placeholder = '',
  multiple = false,
  size = InputSize.medium,
  paginateResponse = false,
}: Props<T>) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const generatedId = uuidV4()
  const [results, setResults] = useState<T[]>([])
  const [searchValue, setSearchValue] = useState<string | string[]>(defaultValue || '')
  const [selected, setSelected] = useState<T | T[]>(defaultSelected)
  const [focused, setFocused] = useState<boolean>(false)
  const [enabled, setEnabled] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const getKey = (result: T) => (resultIdKey ? result[resultIdKey] : JSON.stringify(result))
  const getLabel = (result: T) => {
    if (resultLabelKeyOptional) {
      return result[resultLabelKey]
        ? `${result[resultLabelKeyOptional]} - ${result[resultLabelKey]}`
        : JSON.stringify(result)
    }
    return resultLabelKey ? result[resultLabelKey] : JSON.stringify(result)
  }

  // Handlers for focus and blur
  const handleFocus = () => {
    setEnabled(true)
    setFocused(true)
    setSearchValue('')
  }

  useClickOutside(wrapperRef, () => {
    setEnabled(false)
    setResults([])
    setFocused(false)
  })

  const handleBlur = (e: FocusEvent<HTMLDivElement>) => {
    if ((!selected || !defaultSelected) && !wrapperRef.current?.contains(e.currentTarget)) {
      setSearchValue('')
    }
    setFocused(false)
  }

  // Handlers for search, select and reset
  const handleSearch = (value: string) => {
    setSearchValue(value)
    if (!multiple) {
      setSelected(null)
    }
    if (!value.length) onChange(null)
  }

  const handleSelect = (selection: T) => (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (multiple && Array.isArray(selected)) {
      if (selected.filter(select => select.id === selection.id).length) return
      const updatedSelected = [...selected, selection]
      setSelected(updatedSelected)
      setSearchValue('')
      onChange(updatedSelected)
      setFocused(false)
      document.getElementById(generatedId)?.blur()
    } else {
      setSelected(selection)
      setSearchValue(getLabel(selection))
      onChange(selection)
      setResults([])
    }
  }

  const handleReset = () => {
    if (multiple) {
      setSelected([])
      onChange([])
    } else {
      setSelected(null)
    }
    setSearchValue('')
    setResults([])
  }

  const handleRemoveChip = (item: T) => () => {
    const updatedSelectedItems = (selected as T[]).filter(select => select.id !== item.id)
    setSelected(updatedSelectedItems)
    onChange(updatedSelectedItems)
    setEnabled(false)
    setResults([])
    setFocused(false)
  }

  // Effects
  useEffect(() => {
    if (disabled) setSearchValue('')
  }, [disabled])

  useEffect(() => {
    const delayedSearch = debounce(async () => {
      if (!focused) return
      if ((selected || !enabled || disabled) && !multiple) return
      setLoading(true)
      let response
      if (paginateResponse) {
        const paginated = await get<{results: T[]}>(apiEndpoint, { ...params, search: searchValue })
        response = paginated.results
      } else {
        response = await get<T[]>(apiEndpoint, { ...params, search: searchValue })
      }
      setLoading(false)

      setResults(response)
    }, 500)

    if (searchValue?.length >= minSearchLength) {
      delayedSearch()
    } else {
      setResults([])
    }

    return delayedSearch.cancel
  }, [searchValue, selected, enabled, disabled])

  useEffect(() => {
    if (shouldReset) {
      handleReset()
    }
  }, [shouldReset])

  useEffect(() => {
    setSearchValue(defaultValue || '')
    setSelected(defaultSelected)
  }, [defaultSelected, defaultValue])

  return (
    <div
      id={generatedId}
      ref={wrapperRef}
      onFocus={handleFocus}
      onBlur={handleBlur}
      className={`
        input-wrapper 
        input--${size} 
        autocomplete-input 
        ${disabled ? ' disabled' : ''}
        ${loading ? ' loading' : ''}
        ${(!searchValue && !selected && required) ? ' error' : ''}
      `}
    >
      {
        multiple ? (
          <div className="input-chip-wrapper">
            {label && <label htmlFor={label}>{label}</label>}
            <div className="input-with-chips">
              <div className="chips">
                {Array.isArray(selected) && selected.map(item => (
                  <div key={getLabel(item)} className="chip">
                    <span>
                      {getLabel(item)}
                    </span>
                    <button
                      type="button"
                      onClick={handleRemoveChip(item)}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                ))}
              </div>
              <div>
                <input
                  value={searchValue}
                  placeholder={Array.isArray(selected) && selected.length ? '' : placeholder}
                  className={`input ${errorMessage || (!selected && required) ? 'error' : ''}`}
                  onChange={e => handleSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
        ) : (
          <TextInput
            label={label}
            onChange={handleSearch}
            bindedValue={searchValue as string}
            clearable
            placeholder={placeholder}
          />
        )
      }
      <Loader variant="small" />
      <div className={`results${results?.length > 0 ? ' has-results' : ''}`}>
        {results.map(result => (
          <div className="result" key={getKey(result)} onMouseDown={handleSelect(result)}>
            {getLabel(result)}
          </div>
        ))}
      </div>
      {
        errorMessage && (
          <div className="error-input-message">
            <ErrorOutlineIcon className="icon" />
            <span>
              {errorMessage}
            </span>
          </div>
        )
      }
    </div>
  )
}
