import { deleteRequest, get, patch, post } from '@osrdata/app_core/dist/requests'
import { signal } from '@preact/signals-react'
import terms from 'assets/terms'
import { ToastLevel, ToastSignal } from 'components'
import { Variant, VariantAvailability } from 'types'
import { catchable } from 'utils'

//* --------------- List API URL variants ---------------
export const variantsApi = {
  VARIANTS: (id: string) => `/apt/missions/${id}/variantes/`,
  VARIANT: (id: string, varId: string) => `/apt/missions/${id}/variantes/${varId}`,
  VARIANT_AVAILABILITY: (id: string, varId: string) => `/apt/missions/${id}/variantes/${varId}/disponibilite`,
}

//* --------------- Signal to store variants ---------------
export const VariantsSignal = signal<Variant[]>([])
export const VariantSignal = signal<Variant>(null)
export const VariantAvailabilitySignal = signal<VariantAvailability>(null)

//* --------------- Update variants to the signal ---------------
/**
 * Update variants to the signal
 * @param variants - list of variants
*/
export const updateVariants = (variants: Variant[]) => {
  VariantsSignal.value = variants
}

/**
 * Update Variant to the signal
 * @param variant - variant
*/
export const updateVariant = (variant: Variant) => {
  VariantSignal.value = variant
}

/**
 * Update Variant availability to the signal
 * @param variant - variant
*/
export const updateVariantAvailability = (variant: VariantAvailability) => {
  VariantAvailabilitySignal.value = variant
}

//* --------------- API variants ---------------
/**
 * Fetches all variant for a mission
 */
export const getVariants = (id: string) => catchable(async () => {
  const response = await get<Variant[]>(variantsApi.VARIANTS(id))
  updateVariants(response)
})

/**
 * Fetches a variant by its id
*/
export const getVariant = (id: string, varId: string) => catchable(async () => {
  const response = await get<Variant>(variantsApi.VARIANT(id, varId))
  updateVariant(response)
})

/**
 * Fetches a variant availability by its id
*/
export const getVariantAvailability = (id: string, varId: string) => catchable(async () => {
  VariantAvailabilitySignal.value = null
  const response = await get<VariantAvailability>(variantsApi.VARIANT_AVAILABILITY(id, varId))
  updateVariantAvailability(response)
})

/**
 * Patch a variant
 * @param variant: <Variant>
*/
export const patchVariant = (id: string, variant: Partial<Variant>) => catchable(async () => {
  const response = await patch<Variant>(variantsApi.VARIANT(id, variant.id), variant)

  if (response) {
    ToastSignal.value = { message: terms.Pages.Mission.Form.Variant.successUpdate, severity: ToastLevel.SUCCESS }
    updateVariant(response)
    const newVariants = VariantsSignal.value.map(item => {
      if (item.id === response.id) {
        return response
      }
      return item
    })
    updateVariants(newVariants)
  }
}, true)

/**
 * Post a variant
 * @param variant: <Variant>
 * @param id: <string>
 *
*/
export const postVariant = (id: string, variant: Partial<Variant>) => catchable(async () => {
  const response = await post<Variant>(variantsApi.VARIANTS(id), variant)

  if (response) {
    ToastSignal.value = { message: terms.Pages.Mission.Form.Variant.successCreate, severity: ToastLevel.SUCCESS }
    updateVariant(response)
    updateVariants([...VariantsSignal.value, response])
  }
}, true)

/**
 * Delete a variant
 * @param id: <string>
 * @param varId: <string>
*/
export const deleteVariant = (id: string, varId: string) => catchable(async () => {
  await deleteRequest(variantsApi.VARIANT(id, varId))
  updateVariants(VariantsSignal.value.filter(item => item.id !== varId))
  ToastSignal.value = { message: terms.Pages.Mission.Form.Variant.deleteSuccess, severity: ToastLevel.SUCCESS }
}, true)
