import { ErrorMessage, ExportStatus, Filter, Mission, StopStage } from 'types'

export enum FluxElement {
  HOME = 'HOME',
  SAR = 'SAR',
  LOT = 'LOT',
}

export enum FluxState {
  loaded = 'Chargé',
  empty = 'Vide',
}

export interface SarTms {
  id: string,
  longueur: number,
  poids: number,
  nombreLots: number,
  origine: string,
  destination: string,
  regimeCirculation: string[],
  statutExport: ExportStatus
  errorMessage: ErrorMessage[]
}

export interface FluxType {
  id: string,
  cleFlux: string,
  client: Filter,
  origine: string,
  destination: string,
  estCharge: boolean,
  sartms: SarTms[],
  statutExport: ExportStatus,
  nombreSarTms: number
}

export interface FluxsPaginate {
  count: number,
  next: string,
  previous: string,
  results: FluxType[]
}

export interface LotType {
  id: string,
  poids: number,
  numeroOrdre: number,
  longueur: number,
  nombreWagons: number,
  decalageAcheminementLot: number,
  joursDepart: string[],
  libelle: string
}

export interface TrameType {
  id: string,
  rangLot: number,
  lot: LotType,
  troncon: {
    id: string,
    arretArrivee: StopStage,
    arretDepart: StopStage,
    mission: Mission,
    capaciteLongueur: number,
    capacitePoids: number,
    rang: number,
    remplissage: number,
    sensReleve: string,
    joursArrivee: string[],
    joursDepart: string[],
    tube: {
      id: string,
      libelle: string,
    }
  },
  timeToWait: string,
  jourCirculation: string
  jourCirculationArrivee: string
}

export interface PostTrame {
  mission: Mission,
  origineDestination: string,
  jourCirculations: string,
}

export interface PatchTrame extends PostTrame {
  id: string
}

export interface Planification {
  id: string,
  rangTroncon: number,
  lotsTronconsPlanifies: TrameType[],
}
