/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useRef, useState } from 'react'
import Chevron from '@mui/icons-material/ArrowForwardIos'
import { v4 as uuidV4 } from 'uuid'

import './Accordion.scss'

type Props = {
  title: React.ReactNode
  children: React.ReactNode
  defaultExpanded?: boolean
  className?: string
  handleClick?: () => void
  removeExpand?: boolean
}

export default function Accordion({
  title,
  children,
  defaultExpanded = true,
  className = '',
  removeExpand = false,
  handleClick = () => null,
}: Props) {
  const [expanded, setExpanded] = useState(defaultExpanded)
  const accordionRef = useRef<HTMLDivElement>(null)
  const [isTransitionEnabled, setIsTransitionEnabled] = useState(false)

  useEffect(() => {
    if (accordionRef.current) {
      const { offsetHeight } = accordionRef.current
      if (accordionRef.current) accordionRef.current.style.height = `${offsetHeight}px`

      requestAnimationFrame(async () => {
        setExpanded(defaultExpanded)
        if (accordionRef.current) accordionRef.current.style.height = defaultExpanded ? `${offsetHeight}px` : '0px'
        setIsTransitionEnabled(true)
      })
    }
  }, [accordionRef])

  const handleToggle = () => {
    const { offsetHeight } = accordionRef.current
    handleClick()
    if (offsetHeight > 0) {
      setExpanded(false)
      if (accordionRef.current) accordionRef.current.style.height = '0px'
    } else {
      setExpanded(true)
      if (accordionRef.current) accordionRef.current.style.height = 'auto'
    }
  }

  useEffect(() => {
    if (removeExpand) {
      setExpanded(false)
      if (accordionRef.current) accordionRef.current.style.height = '0px'
    }
  }, [removeExpand])

  return (
    <div
      id={uuidV4()}
      className={
      `accordion${expanded ? ' expanded' : ''}${isTransitionEnabled ? ' enable-transition' : ''} ${className}`
      }
    >
      <h3 onClick={handleToggle}>
        {title}
        <Chevron className="icon" />
      </h3>
      <div className="wrapper" ref={accordionRef}>
        {children}
      </div>
    </div>
  )
}
