import terms from 'assets/terms'
import { get, post } from '@osrdata/app_core/dist/requests'
import { signal } from '@preact/signals-react'
import { ToastLevel, ToastSignal } from 'components'
import { Bfr, Egs, Place } from 'types'
import { Filter, SectionPlanification } from 'types/filters'
import { catchable, saveToLocal } from 'utils'

// List all the API endpoints for filters
export const filtersApi = {
  AXES: '/apt/axes',
  TUBES: '/apt/tubes',
  EGS: '/apt/egs',
  BFR: '/apt/bfr',
  PLACES: '/apt/lieux',
  COMPOSITION: '/apt/indice-composition',
  CCO: '/apt/ccos',
  GESCO: '/apt/gescos',
  TYPE_AGENT: '/apt/type-agent',
  SERIE_EM: '/apt/serie-em',
  ENTITY: '/apt/entites',
  CLIENTS: '/apt/clients',
  MISSIONS: '/apt/missions',
  SECTIONPLANIFICATION: (missionId: string) => `/apt/missions/${missionId}/planification-troncons`,
} as const

// Get filters from local storage
const localAxe = JSON.parse(localStorage.getItem('selectedAxe')) as Filter
const localTube = JSON.parse(localStorage.getItem('selectedTube')) as Filter
const localClientsFlux = JSON.parse(localStorage.getItem('selectedClientsFlux')) as Filter[]
const localSearchFlux = JSON.parse(localStorage.getItem('selectedSearchFlux')) as string

// Create signals for selected filters with local storage as default value
export const SelectedAxeSignal = signal<Filter>(localAxe)
export const SelectedTubeSignal = signal<Filter>(localTube)
export const SelectedEgsSignal = signal<Egs>(null)
export const SelectedBfrSignal = signal<Bfr>(null)
export const SelectedPlaceSignal = signal<Place>(null)
export const SelectedCompositionSignal = signal<Filter>(null)
export const SelectedCcoSignal = signal<Filter>(null)
export const SelectedGescoSignal = signal<Filter>(null)
export const SelectedClientsFluxSignal = signal<Filter[]>(localClientsFlux || [])
export const SelectedSearchFluxSignal = signal<string>(localSearchFlux || '')
export const SectionPlanificationSignal = signal<SectionPlanification[]>([])

/**
 * Update selected axe filter and save it to local storage
 *
 * @param axe selected axe filter
 */
export const updateAxeFilter = (axe: Filter) => {
  SelectedAxeSignal.value = axe
  saveToLocal('selectedAxe', axe)
}

/**
 * Update selected tube filter and save it to local storage
 *
 * @param tube selected tube filter
 */
export const updateTubeFilter = (tube: Filter) => {
  SelectedTubeSignal.value = tube
  saveToLocal('selectedTube', tube)
}

/**
 * Update selected egs filter
 *
 * @param egs selected egs filter
 */
export const updateEgsFilter = (egs: Egs) => {
  SelectedEgsSignal.value = egs
}

/**
 * Update selected bfr filter
 *
 * @param bfr selected bfr filter
*/
export const updateBfrFilter = (bfr: Bfr) => {
  SelectedBfrSignal.value = bfr
}

/**
 * Update selected place filter
 *
 * @param place selected place filter
 */
export const updatePlaceFilter = (place: Place) => {
  SelectedPlaceSignal.value = place
}

/**
 * Update selected composition filter
 *
 * @param composition selected composition filter
 */
export const updateCompositionFilter = (composition: Filter) => {
  SelectedCompositionSignal.value = composition
}

/**
 * Update selected cco filter
 *
 * @param cco selected cco filter
 */
export const updateCcoFilter = (cco: Filter) => {
  SelectedCcoSignal.value = cco
}

/**
 * Update selected gesco filter
 *
 * @param gesco selected gesco filter
 */
export const updateGescoFilter = (gesco: Filter) => {
  SelectedGescoSignal.value = gesco
}

/**
 * Update selected clients filter
 *
 * @param clients selected clients filter
 */
export const updateClientsFluxFilter = (clients: Filter[]) => {
  SelectedClientsFluxSignal.value = clients
  saveToLocal('selectedClientsFlux', clients)
}

/**
 * Update selected search filter
 *
 * @param search selected search filter
 */
export const updateSearchFluxFilter = (search: string) => {
  SelectedSearchFluxSignal.value = search
  saveToLocal('selectedSearchFlux', search)
}

/**
 * Update SectionPlanification
 *
 * @param sectionPlanification - The section planification to update
*/
export const updateSectionPlanification = (sectionPlanification: SectionPlanification[]) => {
  SectionPlanificationSignal.value = sectionPlanification
}

/**
 * Post a client to the API
 * @param client - The client to post
 * @returns The posted client
*/
export const postClient = (client: string) => catchable(async () => {
  const response = await post<Filter>(filtersApi.CLIENTS, { libelle: client })
  ToastSignal.value = { message: terms.Pages.Flux.form.addClientSuccess, severity: ToastLevel.SUCCESS }
  return response
}, true)

/**
 * Get the section planification for a mission and lot
 * @param missionId - The mission id
 * @params lotId - The lot id
 * @returns The section planification
*/
export const getSectionPlanification = (missionId: string, lotId: string) => catchable(async () => {
  const response = await get<SectionPlanification[]>(filtersApi.SECTIONPLANIFICATION(missionId), {
    lot: lotId,
  })
  SectionPlanificationSignal.value = response
  return response
})
