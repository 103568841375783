import { Roles } from 'types'
import { useAppSelector } from 'utils'

/**
 * Roles Hook
 *
 * Add roles logic to the app here, based on cerbere permissions or other role-based access control
 *
 * @returns rolesLoaded: boolean, hasAccess: boolean
 */
export const useRoles = () => {
  const { appPermissions, isLoading, isLogged } = useAppSelector(state => state.user)

  // If the user has the admin role, they have access to everything
  const isAdmin = appPermissions.includes(Roles.ADMIN)

  return {
    rolesLoaded: isLogged && !isLoading,
    roles: appPermissions.filter(role => role.includes('apt::')),
    hasAccess: appPermissions.includes(Roles.ACCESS),
    isAdmin,
    isCat: isAdmin || appPermissions.includes(Roles.CAT),
    isRcps: isAdmin || appPermissions.includes(Roles.RCPS),
    isBaremiste: isAdmin || appPermissions.includes(Roles.BAREMISTE),
  }
}
