import { useEffect, useState } from 'react'
import ResetIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { v4 as uuidV4 } from 'uuid'

import '../wrapper.scss'
import './TextInput.scss'
import { InputSize } from '..'

type Props = {
  defaultValue?: string
  bindedValue?: string
  label?: string
  type?: 'string' | 'number'
  align?: 'left' | 'center' | 'right'
  shouldReset?: boolean
  clearable?: boolean
  disabled?: boolean
  onChange?: (value: string) => void
  errorMessage?: string
  placeholder?: string
  required?: boolean
  size?: InputSize
  Icon?: React.ReactElement
  onClick?: () => void
}

/**
 * Text input component
 * @param defaultValue - The default value of the input
 * @param bindedValue - The value to bind to the input
 * @param label - The label of the input
 * @param type - The type of the input (string or number)
 * @param align - The alignment of the input (left, center or right)
 * @param clearable - Whether the input is clearable
 * @param disabled - Whether the input is disabled
 * @param shouldReset - Whether the input should reset
 * @param errorMessage - The error message to display
 * @param onChange - The function to call when the input changes
 * @param required - Whether the input is required
 * @param placeholder - The placeholder of the input
 * @param size - The size of the input
 * @param Icon - The icon to display
 *
 * @returns The Text component
 */
export default function Text({
  required = false,
  defaultValue = '',
  bindedValue = '',
  label = '',
  type = 'string',
  align = 'left',
  clearable = false,
  disabled = false,
  shouldReset = false,
  errorMessage = '',
  placeholder = '',
  size = InputSize.medium,
  Icon = null,
  onChange = () => { /* to implement */ },
  onClick = () => { /* to implement */ },
}: Props) {
  const [value, setValue] = useState(defaultValue || bindedValue)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = type === 'number'
      ? event.target.value.replace(/\D/g, '')
      : event.target.value

    setValue(inputValue)
    onChange(inputValue)
  }

  const handleReset = () => {
    setValue('')
    onChange('')
  }

  useEffect(() => {
    setValue(bindedValue)
  }, [bindedValue])

  useEffect(() => {
    if (shouldReset) {
      handleReset()
    }
  }, [shouldReset])

  const generatedId = uuidV4()

  return (
    <div className={`text input-wrapper input--${size} align-${align}${disabled ? ' disabled' : ''}`}>
      {label && <label htmlFor={generatedId}>{label}</label>}
      <div className="input-container">
        <input
          id={generatedId}
          className={`input ${errorMessage || (!value && required) ? 'error' : ''}`}
          type="text"
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={disabled}
        />
        {
          value && clearable && (
            <button
              type="button"
              className="clear-button"
              onClick={handleReset}
            >
              <ResetIcon className="icon" />
            </button>
          )
        }
        {
          Icon && (
            <button
              type="button"
              className="clear-button"
              onClick={onClick}
            >
              {Icon}
            </button>
          )
        }
      </div>
      {
        errorMessage && (
          <div className="error-input-message">
            <ErrorOutlineIcon className="icon" />
            <span>
              {errorMessage}
            </span>
          </div>
        )
      }
    </div>
  )
}
