import { ReactElement, ReactNode } from 'react'
import SuccessIcon from '@mui/icons-material/Done'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import WarningIcon from '@mui/icons-material/WarningAmber'
import terms from 'assets/terms'
import { Button, ModalSignal } from 'components'
import { Signal } from '@preact/signals-react'
import { ModalType } from '../ModalWrapper'

import './ImportModal.scss'

interface Props {
  title: string;
  info?: string[];
  type?: ModalType;
  textButton?: string;
  handleClickConfim?: () => void;
  children?: ReactElement | string | ReactNode;
}

export const DisabledModalButtonSignal = new Signal<boolean>(false)

export default function ImportModal({
  title,
  info,
  type,
  textButton = '',
  handleClickConfim = () => { /* ... */ },
  children = null,
}: Props): ReactElement {
  /**
   *  Reset the signal to close the modal
   */
  const resetSignal = () => {
    ModalSignal.value = null
  }
  /**
   *  Get the modal attributes
   * @param modalType ModalType
   * @returns Object { icon: ReactElement, handleClick: () => void, handleClose: () => void}
   */
  const getModalAttributes = (modalType: ModalType) => {
    switch (modalType) {
      case ModalType.ERROR:
        return {
          icon: <ErrorIcon />,
          handleClick: resetSignal,
          handleClose: null,
        }
      case ModalType.SUCCESS:
        return {
          icon: <SuccessIcon />,
          handleClose: null,
          handleClick: resetSignal,
        }
      case ModalType.WARNING:
        return {
          icon: <WarningIcon />,
          handleClose: resetSignal,
          handleClick: handleClickConfim,
        }
      case ModalType.INFO:
        return {
          icon: <ErrorIcon />,
          handleClick: resetSignal,
          handleClose: null,
        }
      default:
        return null
    }
  }

  return (
    <div className="modal-import">
      {getModalAttributes(type) && (
        <div className={`header--${type}`}>
          {getModalAttributes(type).icon}
        </div>
      )}
      <div className="title">
        {title}
      </div>
      {info && (
        <div className={`info--${type}`}>
          {type === ModalType.ERROR && (
            <div className="error-icon">
              <ErrorIcon />
            </div>
          )}
          <div className="messages-wrapper">
            {
              info.map(i => (
                <span key={i}>
                  {i}
                </span>
              ))
            }
          </div>
        </div>
      )}
      {children}
      <div className="buttons">
        {
          !!getModalAttributes(type).handleClose && (
            <button
              type="button"
              className="cancel"
              onClick={getModalAttributes(type).handleClose}
            >
              {terms.Common.cancel}
            </button>
          )
        }
        <Button
          text={textButton}
          onClick={getModalAttributes(type).handleClick}
          disabled={DisabledModalButtonSignal.value}
        />
      </div>
    </div>
  )
}
