export enum Roles {
  ACCESS = 'apt::USER',
  ADMIN = 'apt::ADMIN',
  CAT = 'apt::CAT',
  RCPS = 'apt::RCPS',
  BAREMISTE = 'apt::BAREMISTE',
}

export enum Paths {
  HOME = '/',
  MISSIONS = '/missions',
  MISSIONS_DETAILS = '/missions/:missionId',
  BAREMES = '/baremes',
  BAREMES_DETAILS = '/baremes/:baremeId',
  FLUX = '/flux',
  FLUX_DETAILS = '/flux/:fluxId',
}
