/* eslint-disable no-promise-executor-return */
import { get, patch, post } from '@osrdata/app_core/dist/requests'
import { signal } from '@preact/signals-react'
import terms from 'assets/terms'
import { ToastLevel, ToastSignal } from 'components'
import { ModalType } from 'components/modals/ModalWrapper'
import { getTask } from 'services/common/tasks'
import { ExportStatus, SarTms, TaskType } from 'types'
import { catchable } from 'utils'
import { getFlux } from './fluxs'

//* --------------- List API URL sar ---------------
export const sarsApi = {
  SARS: (fluxId: string) => `/apt/flux/${fluxId}/sarTms`,
  SAR: (fluxId: string, id: string) => `/apt/flux/${fluxId}/sarTms/${id}`,
  SARS_EXPORT: (fluxId: string) => `/apt/flux/${fluxId}/publish`,
}

//* --------------- Signal to store sar ---------------
export const SarsSignal = signal<SarTms[]>([])
export const SarSignal = signal<SarTms>(null)
export const SarsToImportSignal = signal<string[]>([])

//* --------------- Update sar to the signal ---------------
/**
 * Update sars to the signal
 *
 * @param sars - list of sars
*/
export const updateSars = (sars: SarTms[]) => {
  SarsSignal.value = sars
}

/**
 * Update Sar to the signal
 * @param sar - sar
*/
export const updateSar = (sar: SarTms) => {
  SarSignal.value = sar
}

//* --------------- API sar ---------------

/**
 * Fetches a sar by its id
 * @param fluxId - flux id
 * @param id - sar id
 * @returns SarTms
*/
export const getSar = (fluxId: string, id: string) => catchable(async () => {
  SarSignal.value = null
  const response = await get<SarTms>(sarsApi.SAR(fluxId, id))
  updateSar(response)
})

/**
 * Fetches all sars by flux id
 * @param fluxId - flux id
 * @returns SarTms[]
*/
export const getSars = (fluxId: string) => catchable(async () => {
  const response = await get<SarTms[]>(sarsApi.SARS(fluxId))
  updateSars(response)
})

/**
 * Patch sar by its id
 *
 * @param fluxId - flux id
 * @param id - sar id
 * @param data - data to patch
*/
export const patchSar = (fluxId: string, id: string, data: Partial<SarTms>) => catchable(async () => {
  const response = await patch<SarTms>(sarsApi.SAR(fluxId, id), data)

  if (response) {
    ToastSignal.value = { message: terms.Pages.Flux.Sar.updateSuccess, severity: ToastLevel.SUCCESS }
    updateSar(response)
    updateSars(SarsSignal.value.map(sar => (sar.id === id ? response : sar)))
  }
}, true)

/**
 * Get the status of the task
 * @param id - task id
 */
export const getTaskExportSars = async (
  taskId: string,
  fluxId: string,
  exportModalCallback: (type: ModalType) => void,
) => {
  let result = await getTask(taskId) as TaskType

  const interval = setInterval(async () => {
    switch (result.status) {
      case ExportStatus.SUCCESS:
        SarSignal.value = null
        clearInterval(interval)
        exportModalCallback(ModalType.SUCCESS)
        getSars(fluxId)
        getFlux(fluxId)
        break
      case ExportStatus.FAILURE:
        SarSignal.value = null
        clearInterval(interval)
        exportModalCallback(ModalType.ERROR)
        getSars(fluxId)
        getFlux(fluxId)
        break
      case ExportStatus.PENDING:
        result = await getTask(taskId) as TaskType
        break
      default:
        break
    }
  }, 1000)
}

/**
 * Export sars to RUS
*/
export const exportSars = (
  fluxId: string,
  exportModalCallback: (type: ModalType) => void,
) => catchable(async () => {
  const response = await post(sarsApi.SARS_EXPORT(fluxId), {
    sartms: SarsToImportSignal.value,
  })
  SarsToImportSignal.value = []

  if (response) {
    exportModalCallback(ModalType.LOADING)
    getTaskExportSars(response.taskId, fluxId, exportModalCallback)
  }
}, true)
