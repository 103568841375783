import { deleteRequest, get, patch, post } from '@osrdata/app_core/dist/requests'
import { signal } from '@preact/signals-react'
import terms from 'assets/terms'
import { ToastLevel, ToastSignal } from 'components'
import { Agent, LocomotiveType, Step } from 'types'
import { catchable } from 'utils'

//* --------------- List API URL convoying steps ---------------
export const convoyingStepApi = {
  VARIANT_STEP: (id: string, varId: string, stepId: string) => (
    `/apt/missions/${id}/variantes/${varId}/etapes/${stepId}`
  ),
  VARIANT_STEPS: (id: string, varId: string) => (
    `/apt/missions/${id}/variantes/${varId}/etapes`
  ),
  LOCOMOTIVES: (id: string, varId: string, stepId: string) => (
    `/apt/missions/${id}/variantes/${varId}/etapes/${stepId}/locomotives`
  ),
  LOCOMOTIVE: (id: string, varId: string, stepId: string, locoId: string) => (
    `/apt/missions/${id}/variantes/${varId}/etapes/${stepId}/locomotives/${locoId}`
  ),
  AGENTS: (id: string, varId: string, stepId: string) => (
    `/apt/missions/${id}/variantes/${varId}/etapes/${stepId}/agents`
  ),
  AGENT: (id: string, varId: string, stepId: string, agentId: string) => (
    `/apt/missions/${id}/variantes/${varId}/etapes/${stepId}/agents/${agentId}`
  ),
}

//* --------------- Signal to store variant steps ---------------
export const VariantStepSignal = signal<Step>(null)
export const VariantStepsSignal = signal<Step[]>([])

//* --------------- Update variant steps to the signal ---------------
/**
 * Update variant step to the signal
 * @param step - step
*/
export const updateVariantStep = (step: Step) => {
  VariantStepSignal.value = step
}

/**
 * Update variant steps to the signal
 * @param steps - list of steps
*/
export const updateVariantSteps = (steps: Step[]) => {
  VariantStepsSignal.value = steps
}

//* --------------- API variant steps ---------------
/**
 * Fetches all steps for a variant
 * @param id: string  Mission id
 * @param varId: string Variant id
*/
export const getVariantSteps = (id: string, varId: string) => catchable(async () => {
  const response = await get<Step[]>(convoyingStepApi.VARIANT_STEPS(id, varId))
  updateVariantSteps(response)
})

/**
 * Fetches a step by its id
 * @param id: string  Mission id
 * @param varId: string Variant id
 * @param stepId: string Step id
*/
export const getVariantStep = (id: string, varId: string, stepId: string) => catchable(async () => {
  const response = await get<Step>(convoyingStepApi.VARIANT_STEP(id, varId, stepId))
  updateVariantStep(response)
})

/**
 * Patch a step
 * @param id: string  Mission id
 * @param varId: string Variant id
 * @param step:Partial <Step>
 */
export const patchVariantStep = (
  id: string,
  varId: string,
  step: Partial<Step>,
) => catchable(async () => {
  const response = await patch<Step>(convoyingStepApi.VARIANT_STEP(id, varId, step.id), step)
  if (response) {
    updateVariantStep(response)
    ToastSignal.value = {
      message: terms.Pages.Mission.Form.ConvoyingStep.ToastMessage.Success.updateStep,
      severity: ToastLevel.SUCCESS,
    }
    const newSteps = VariantStepsSignal.value.map(s => {
      if (s.id === response.id) {
        return response
      }
      return s
    })
    updateVariantSteps(newSteps)
  }
}, true)

/**
 * Post a step
 * @param id: string  Mission id
 * @param varId: string Variant id
 * @param step:Partial <Step>
*/
export const postVariantStep = (
  id: string,
  varId: string,
  step: Partial<Step>,
) => catchable(async () => {
  VariantStepSignal.value = null
  const reponse = await post<Step>(convoyingStepApi.VARIANT_STEPS(id, varId), step)

  if (reponse) {
    ToastSignal.value = {
      message: terms.Pages.Mission.Form.ConvoyingStep.ToastMessage.Success.addStep,
      severity: ToastLevel.SUCCESS,
    }
    updateVariantStep(reponse)
    updateVariantSteps([...VariantStepsSignal.value, reponse])
  }
}, true)

/**
 * Delete a step
 * @param id: string  Mission id
 * @param varId: string Variant id
 * @param stepId: string Step id
*/
export const deleteVariantStep = (id: string, varId: string, stepId: string) => catchable(async () => {
  await deleteRequest(convoyingStepApi.VARIANT_STEP(id, varId, stepId))
  updateVariantSteps(VariantStepsSignal.value.filter(step => step.id !== stepId))
  ToastSignal.value = {
    message: terms.Pages.Mission.Form.ConvoyingStep.ToastMessage.Success.deleteStep,
    severity: ToastLevel.SUCCESS,
  }
}, true)

/**
 * Get locomotives for a step
 * @param id
 * @param varId
 * @param stepId
 * @returns
 */
export const getLocomotives = (id: string, varId: string, stepId: string) => catchable(async () => {
  const response = await get<LocomotiveType[]>(convoyingStepApi.LOCOMOTIVES(id, varId, stepId))
  updateVariantStep({
    ...VariantStepSignal.value,
    locomotives: response,
  })
})

/**
 * get a locomotive by its id
 * @param id
 * @param varId
 * @param stepId
 * @param locoId
 */
export const getLocomotive = (id: string, varId: string, stepId: string, locoId: string) => catchable(async () => {
  const response = await get<LocomotiveType>(convoyingStepApi.LOCOMOTIVE(id, varId, stepId, locoId))
  updateVariantStep({
    ...VariantStepSignal.value,
    locomotives: VariantStepSignal.value.locomotives.map(loco => {
      if (loco.id === locoId) {
        return response
      }
      return loco
    }),
  })
})

/**
 * Patch a locomotive by its id
 * @param id string
 * @param varId string
 * @param stepId string
 * @param locoId string
 * @param locomotive  Partial<LocomotiveType>
 */
export const patchLocomotive = (
  id: string,
  varId: string,
  stepId: string,
  locoId: string,
  locomotive: Partial<LocomotiveType>,
) => catchable(async () => {
  const response = await patch<LocomotiveType>(convoyingStepApi.LOCOMOTIVE(id, varId, stepId, locoId), locomotive)
  if (response) {
    ToastSignal.value = {
      message: terms.Pages.Mission.Form.ConvoyingStep.ToastMessage.Success.updateLocomotive,
      severity: ToastLevel.SUCCESS,
    }
    updateVariantStep({
      ...VariantStepSignal.value,
      locomotives: VariantStepSignal.value.locomotives.map(loco => {
        if (loco.id === locoId) {
          return response
        }
        return loco
      }),
    })
  }
}, true)

/**
 * Post a locomotive
 * @param id string
 * @param varId string
 * @param stepId string
 * @param locomotive  Partial<LocomotiveType>
 */
export const postLocomotive = (
  id: string,
  varId: string,
  stepId: string,
  locomotive: Partial<LocomotiveType>,
  step: Step,
) => catchable(async () => {
  const response = await post<LocomotiveType>(convoyingStepApi.LOCOMOTIVES(id, varId, stepId), locomotive)

  if (response) {
    ToastSignal.value = {
      message: terms.Pages.Mission.Form.ConvoyingStep.ToastMessage.Success.addLocomotive,
      severity: ToastLevel.SUCCESS,
    }
    updateVariantStep({
      ...step,
      locomotives: [
        ...step.locomotives,
        response,
      ],
    })
  }
}, true)

/**
 * Delete a locomotive
 * @param id string
 * @param varId string
 * @param stepId string
 * @param locoId string
*/
export const deleteLocomotive = (id: string, varId: string, stepId: string, locoId: string) => catchable(async () => {
  await deleteRequest(convoyingStepApi.LOCOMOTIVE(id, varId, stepId, locoId))
  updateVariantStep({
    ...VariantStepSignal.value,
    locomotives: VariantStepSignal.value.locomotives.filter(loco => loco.id !== locoId),
  })
  ToastSignal.value = {
    message: terms.Pages.Mission.Form.ConvoyingStep.ToastMessage.Success.deleteLocomotive,
    severity: ToastLevel.SUCCESS,
  }
}, true)

/**
 * Get agents for a step
 * @param id
 * @param varId
 * @param stepId
 * @returns
*/
export const getAgents = (id: string, varId: string, stepId: string) => catchable(async () => {
  const response = await get<Agent[]>(convoyingStepApi.AGENTS(id, varId, stepId))
  updateVariantStep({
    ...VariantStepSignal.value,
    agents: response,
  })
})

/**
 * get an agent by its id
 * @param id
 * @param varId
 * @param stepId
 * @param agentId
*/
export const getAgent = (id: string, varId: string, stepId: string, agentId: string) => catchable(async () => {
  const response = await get<Agent>(convoyingStepApi.AGENT(id, varId, stepId, agentId))
  updateVariantStep({
    ...VariantStepSignal.value,
    agents: VariantStepSignal.value.agents.map(agent => {
      if (agent.id === agentId) {
        return response
      }
      return agent
    }),
  })
})

/**
 * Post an agent
 * @param id
 * @param varId
 * @param stepId
 * @param agent Partial<Agent>
*/
export const postAgent = (
  id: string,
  varId: string,
  stepId: string,
  agent: Partial<Agent>,
  step: Step,
) => catchable(async () => {
  const response = await post<Agent>(convoyingStepApi.AGENTS(id, varId, stepId), agent)

  if (response) {
    ToastSignal.value = {
      message: terms.Pages.Mission.Form.ConvoyingStep.ToastMessage.Success.addAgent,
      severity: ToastLevel.SUCCESS,
    }
    updateVariantStep({
      ...step,
      agents: [
        ...step.agents,
        response,
      ],
    })
  }
}, true)

/**
 * Delete an agent
 * @param id
 * @param varId
 * @param stepId
 * @param agentId
 */
export const deleteAgent = (id: string, varId: string, stepId: string, agentId: string) => catchable(async () => {
  await deleteRequest(convoyingStepApi.AGENT(id, varId, stepId, agentId))

  updateVariantStep({
    ...VariantStepSignal.value,
    agents: VariantStepSignal.value.agents.filter(agent => agent.id !== agentId),
  })
  ToastSignal.value = {
    message: terms.Pages.Mission.Form.ConvoyingStep.ToastMessage.Success.deleteAgent,
    severity: ToastLevel.SUCCESS,
  }
}, true)
