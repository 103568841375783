import { ReactComponent as WeightIcon } from 'assets/icons/weight.svg'
import { ReactComponent as SizeIcon } from 'assets/icons/size.svg'
import { ReactComponent as BurgerIcon } from 'assets/icons/burger.svg'
import { ReactComponent as TrainIcon } from 'assets/icons/train.svg'
import ArrowRightIcon from '@mui/icons-material/ArrowForward'
import terms from 'assets/terms'
import { Matrice } from 'types'
import { formatHour } from 'utils'

import './SectionCard.scss'

interface Props {
  day: Matrice
}

export default function SectionCard({ day }: Props) {
  if (!day.horaireArrivee) {
    return (
      <div className="section-card">
        <div className="wrapper" />
      </div>
    )
  }
  const info = {
    [terms.Pages.Missions.section.remplissage]: `${day.remplissage}%`,
    [terms.Pages.Missions.section.weight]: `${day.capacitePoids}t | ${day.capaciteLongueur}m`,
    [terms.Pages.Missions.section.hours]: (
      <p className="hours">
        <span>
          {formatHour(day.horaireDepart)}
        </span>
        <ArrowRightIcon />
        <span>
          {formatHour(day.horaireArrivee)}
        </span>
      </p>
    ),
    [terms.Pages.Missions.section.train]: (day.numeroSillon.map(val => (
      <span key={val}>
        {val}
      </span>
    ))),
    [terms.Pages.Missions.section.addValue]: `${day.cumulPoids}t | ${day.cumulLongueur}m`,
    [terms.Pages.Missions.section.restValue]: `${day.residuelPoids}t | ${day.residuelLongueur}m`,
  }

  /**
   * Function to add alert class to the section card
   * @param label string
   * @param alert boolean
   * @returns string
   */
  const getClassName = (label: string, alert: boolean): string => (
    (label === terms.Pages.Missions.section.addValue && alert) ? 'alert' : '')

  return (
    <div className="section-card">
      <div className={`wrapper ${day.alerteCapacite ? 'alert-capacity' : ''}`}>
        <div className="alert-info">
          <span>
            {terms.Pages.Missions.section.overcapacity}
          </span>
        </div>
        <div>
          <div className="info">
            {Object.entries(info).map(([key, value]) => (
              <div
                key={key}
                className={
                  `${key === terms.Pages.Missions.section.train
                    ? 'train-wrapper'
                    : ''}${getClassName(key, day.alerteCapacite)}`
                  }
              >
                <p>{key}</p>
                <p className={`${key === terms.Pages.Missions.section.train ? 'trains' : ''}`}>{value}</p>
              </div>
            ))}
          </div>
          <div className="lots flex-column-center">
            {day.lots.map(lot => (
              <div className="lot" key={lot.id}>
                <div className="title flex-center">
                  <h4>{lot.libelle}</h4>
                  <BurgerIcon />
                </div>
                <div className="details flex-center">
                  <div>
                    <WeightIcon />
                    {`${lot.poids}t`}
                  </div>
                  <div>
                    <SizeIcon />
                    {`${lot.longueur}m`}
                  </div>
                  <div>
                    <TrainIcon />
                    {`${lot.nombreWagons}wg`}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
