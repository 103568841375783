import { ReactElement } from 'react'
import { TrameType } from 'types'
import { ReactComponent as Train } from 'assets/icons/trainView.svg'

interface Props {
  data: TrameType
}

export default function TrameDetail({ data }: Props): ReactElement {
  return (
    <div key={data.id} className="trame">
      <div className="stage">
        <div className="chip" />
        <span className="place">
          {data.troncon.arretDepart?.lieu?.ngramme}
        </span>
        <span className="hour">
          {data.troncon.arretDepart?.heureDepart.slice(0, 5)}
        </span>
        <span className="day">
          {data.jourCirculation}
        </span>
      </div>
      <div className="line" />
      <div className="section-name">
        <div>
          <Train />
        </div>
        <span className="libelle">
          {data.troncon.mission?.missionId}
        </span>
      </div>
      <div className="line" />
      <div className="stage">
        <div className="chip" />
        <span className="place">
          {data.troncon.arretArrivee?.lieu?.ngramme}
        </span>
        <span className="hour">
          {data.troncon.arretArrivee?.heureArrivee.slice(0, 5)}
        </span>
        <span className="day">
          {data.jourCirculationArrivee}
        </span>
      </div>
    </div>
  )
}
