import { deleteRequest, get, patch, post } from '@osrdata/app_core/dist/requests'
import { signal } from '@preact/signals-react'
import terms from 'assets/terms'
import { ToastLevel, ToastSignal } from 'components'
import { LotType, PatchTrame, Planification, PostTrame } from 'types'
import { catchable } from 'utils'

//* --------------- List API URL lot ---------------
export const lotsApi = {
  LOTS: (fluxId: string, sarId: string) => `/apt/flux/${fluxId}/sarTms/${sarId}/lots`,
  LOT: (fluxId: string, sarId: string, id:string) => `/apt/flux/${fluxId}/sarTms/${sarId}/lots/${id}`,
  TRAMES: (
    fluxId: string,
    sarId: string,
    lotId: string,
  ) => `/apt/flux/${fluxId}/sarTms/${sarId}/lots/${lotId}/planifications`,
  TRAME: (
    fluxId: string,
    sarId: string,
    lotId: string,
    id: string,
  ) => `/apt/flux/${fluxId}/sarTms/${sarId}/lots/${lotId}/planifications/${id}`,
}

//* --------------- Signal to store lot ---------------
export const LotsSignal = signal<LotType[]>([])
export const LotSignal = signal<LotType>(null)
export const TramesSignal = signal<Planification[]>([])

//* --------------- Update lot to the signal ---------------
/**
 * Update lots to the signal
 *
 * @param lots - list of lots
*/
export const updateLots = (lots: LotType[]) => {
  LotsSignal.value = lots
}

/**
 * Update lot to the signal
 * @param lot - lot
*/
export const updateLot = (lot: LotType) => {
  LotSignal.value = lot
}

//* --------------- API lot ---------------

/**
 * Fetches a lot by its id
 * @param fluxId - flux id
 * @param sarId - sar id
 * @param id - lot id
 * @returns SarTms
*/
export const getLot = (fluxId: string, sarId: string, id: string) => catchable(async () => {
  LotSignal.value = null
  const response = await get<LotType>(lotsApi.LOT(fluxId, sarId, id))
  updateLot(response)
})

/**
 * Fetches all sars by flux id
 * @param fluxId - flux id
 * @param sarId - sar id
 * @returns SarTms[]
*/
export const getLots = (fluxId: string, sarId: string) => catchable(async () => {
  const response = await get<LotType[]>(lotsApi.LOTS(fluxId, sarId))
  updateLots(response)
})

/**
 * Patch a lot by its id
 * @param fluxId - flux id
 * @param sarId - sar id
 * @param id - lot id
 * @param data - data to patch the lot
 * @returns LotType
*/
export const patchLot = (fluxId: string, sarId: string, id: string, data: Partial<LotType>) => catchable(async () => {
  const response = await patch<LotType>(lotsApi.LOT(fluxId, sarId, id), data)
  updateLot(response)
  ToastSignal.value = { message: terms.Pages.Flux.Lot.updateSuccess, severity: ToastLevel.SUCCESS }
}, true)

/**
 * Fetches all trames by lot id
 * @param fluxId - flux id
 * @param sarId - sar id
 * @param lotId - lot id
 * @returns TrameType[][] - list of trames
*/
export const getTrames = (fluxId: string, sarId: string, lotId: string) => catchable(async () => {
  const response = await get<Planification[]>(lotsApi.TRAMES(fluxId, sarId, lotId))
  TramesSignal.value = response
})

/**
 * Post a trame by lot id
 * @param fluxId - flux id
 * @param sarId - sar id
 * @param lotId - lot id
 * @param data - data to post the trame
*/
export const postTrame = (
  fluxId: string,
  sarId: string,
  lotId: string,
  data: Partial<PostTrame>,
) => catchable(async () => {
  await post<Planification>(lotsApi.TRAMES(fluxId, sarId, lotId), {
    missionId: data.mission?.id,
    origineDestination: data.origineDestination,
    jourCirculations: data.jourCirculations,
  })
  getTrames(fluxId, sarId, lotId)
  ToastSignal.value = { message: terms.Pages.Flux.Lot.Trame.postSuccess, severity: ToastLevel.SUCCESS }
}, true)

/**
 * Patch a trame by its id
 * @param fluxId - flux id
 * @param sarId - sar id
 * @param lotId - lot id
 * @param id - trame id
 * @param data - data to patch the trame
*/
export const patchTrame = (
  fluxId: string,
  sarId: string,
  lotId: string,
  id: string,
  data: Partial<PatchTrame>,
) => catchable(async () => {
  await patch<Planification>(lotsApi.TRAME(fluxId, sarId, lotId, id), {
    missionId: data.mission?.id,
    origineDestination: data.origineDestination,
    jourCirculations: data.jourCirculations,
  })
  getTrames(fluxId, sarId, lotId)
  ToastSignal.value = { message: terms.Pages.Flux.Lot.Trame.updateSuccess, severity: ToastLevel.SUCCESS }
}, true)

/**
 * Delete a trame by its id
 * @param fluxId - flux id
 * @param sarId - sar id
 * @param lotId - lot id
 * @param id - trame id
*/
export const deleteTrame = (fluxId: string, sarId: string, lotId: string, id: string) => catchable(async () => {
  await deleteRequest(lotsApi.TRAME(fluxId, sarId, lotId, id))
  getTrames(fluxId, sarId, lotId)
  ToastSignal.value = { message: terms.Pages.Flux.Lot.Trame.deleteSuccess, severity: ToastLevel.SUCCESS }
}, true)
