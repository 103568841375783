import { ReactElement, useEffect, useState } from 'react'
import {
  MissionSignal,
  getMission,
  getVariants,
} from 'services'
import { MissionElement } from 'types/missions'
import { ImportModal, Loader, ModalSignal } from 'components'
import terms from 'assets/terms'
import { ModalType } from 'components/modals/ModalWrapper'
import MissionEngine from './engine/MissionEngine'
import MissionHeader from './header/MissionHeader'
import MissionHome from './home/MissionHome'
import MissionSection from './section/MissionSection'
import MissionSideMenu from './side/MissionSideMenu'
import MissionStep from './step/MissionStep'
import MissionVariant from './variant/MissionVariant'

import './MissionPage.scss'

export default function MissionPage() {
  const [elementSelected, setElementSelected] = useState<MissionElement>(MissionElement.HOME)
  const missionId = sessionStorage.getItem('missionId')
  useEffect(() => {
    if (!missionId) return
    getMission(missionId)
    getVariants(missionId)
  }, [])

  if (!MissionSignal.value) {
    return (
      <div className="loading-wrapper">
        <Loader />
      </div>
    )
  }

  const handleDelete = (deleteMethod: () => Promise<void | {error: unknown}>) => {
    deleteMethod().then(() => {
      setElementSelected(MissionElement.HOME)
      ModalSignal.value = null
    })
  }

  const openModalDelete = (
    message: string,
    onDelete: () => Promise<void | {error: unknown}>,
  ) => {
    ModalSignal.value = (
      <ImportModal
        title={message}
        handleClickConfim={() => handleDelete(onDelete)}
        type={ModalType.WARNING}
        textButton={terms.Common.continue}
      />
    )
  }

  const getMissionElement = (): ReactElement => {
    switch (elementSelected) {
      case MissionElement.VARIANT:
        return <MissionVariant onOpenDelete={openModalDelete} />
      case MissionElement.SECTION:
        return <MissionSection onOpenDelete={openModalDelete} />
      case MissionElement.STEP:
        return <MissionStep onOpenDelete={openModalDelete} />
      case MissionElement.ENGINE:
        return <MissionEngine />
      case MissionElement.HOME:
        return <MissionHome />
      default:
        return <MissionHome />
    }
  }

  return (
    <div
      id="missionPage"
      className="page"
    >
      <MissionHeader />
      <div className="content">
        <MissionSideMenu
          missionId={MissionSignal.value.missionId}
          setElementSelected={setElementSelected}
          elementSelected={elementSelected}
        />
        {getMissionElement()}
      </div>
    </div>
  )
}
