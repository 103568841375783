import terms from 'assets/terms'
import { ReactElement, useState } from 'react'
import {
  FluxsSignal,
  SelectedClientsFluxSignal,
  SelectedSearchFluxSignal,
  filtersApi,
  getFluxs,
  updateClientsFluxFilter,
  updateSearchFluxFilter,
} from 'services'
import { Filter } from 'types'
import { AutocompleteInput, TextInput } from 'components/inputs'
import CheckIcon from '@mui/icons-material/Check'
import { Button, ButtonStyle, ModalSignal } from 'components'
import { ButtonSize } from 'components/button/Button'

import './FilterFluxsModal.scss'

export default function FilterFluxsModal(): ReactElement {
  const clientsSignal = SelectedClientsFluxSignal.value
  const searchSignal = SelectedSearchFluxSignal.value

  const [filterClients, setFilterClients] = useState<Filter[]>(clientsSignal || [])
  const [filterSearch, setFilterSearch] = useState<string>(searchSignal || '')

  const closeModal = () => { ModalSignal.value = null }

  const handleValidate = () => {
    FluxsSignal.value = null
    updateClientsFluxFilter(filterClients)
    updateSearchFluxFilter(filterSearch)
    getFluxs()
    closeModal()
  }

  return (
    <div className="filter-fluxs">
      <h2>
        {terms.Pages.Fluxs.FilterModal.title}
      </h2>
      <AutocompleteInput
        apiEndpoint={filtersApi.CLIENTS}
        label={terms.Pages.Fluxs.FilterModal.clients}
        resultIdKey="id"
        resultLabelKey="libelle"
        minSearchLength={0}
        onChange={val => setFilterClients(val as Filter[])}
        placeholder={terms.Pages.Fluxs.FilterModal.clientsPlaceholder}
        defaultSelected={filterClients || []}
        multiple
      />
      <TextInput
        label={terms.Pages.Fluxs.FilterModal.search}
        placeholder={terms.Pages.Fluxs.FilterModal.searchPlaceholder}
        onChange={setFilterSearch}
        defaultValue={filterSearch}
        bindedValue={filterSearch}
      />
      <div className="buttons">
        <Button
          text={terms.Common.cancel}
          onClick={closeModal}
          style={ButtonStyle.borderLessPrimary}
          size={ButtonSize.xlarge}
        />
        <Button
          text={terms.Common.validate}
          onClick={handleValidate}
          style={ButtonStyle.primary}
          icon={<CheckIcon />}
          size={ButtonSize.xlarge}
        />
      </div>
    </div>
  )
}
