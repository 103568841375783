import terms from 'assets/terms'
import { ReactElement, useState } from 'react'
import { Entity, Filter, LocomotivePosition, LocomotiveType, Serie } from 'types'
import { ReactComponent as Trash } from 'assets/icons/trash.svg'
import { AutocompleteInput, CheckInput, SelectInput } from 'components'
import { filtersApi } from 'services'
import { Engine } from '../MissionEngine'

interface Props {
  locomotive: LocomotiveType
  index: number
  handleDelete: (type: string, id: string) => void
  handleChange: <T>(value: T, key: string, id: string, type: Engine) => void
}

export function Locomotive({ locomotive, index, handleChange, handleDelete }: Props): ReactElement {
  const [ccoChecked, setCcoChecked] = useState(!!locomotive.ccoAssistance)
  const [shouldReset, setShouldReset] = useState(false)

  const handleCcoChecked = (val: boolean) => {
    setCcoChecked(val)
    if (!val) {
      setShouldReset(true)
      handleChange<Entity>(null, 'entiteAssistance', locomotive.id, Engine.LOCOMOTIVES)
    }
  }

  const handleCheckCCOAssistance = () => {
    handleChange<Filter>(null, 'ccoAssistance', locomotive.id, Engine.LOCOMOTIVES)
  }

  return (
    <div className="form">
      <div className="title">
        <span>
          {`${terms.Pages.Mission.Form.ConvoyingStep.locomotive} ${index + 1}`}
        </span>
        <button
          type="button"
          className="button"
          onClick={() => handleDelete('locomotive', locomotive.id)}
        >
          <Trash />
        </button>
      </div>
      <div className="inputs">
        <AutocompleteInput<Serie>
          label={terms.Pages.Mission.Form.ConvoyingStep.codeEm}
          apiEndpoint={filtersApi.SERIE_EM}
          resultIdKey="id"
          resultLabelKey="libelle"
          defaultValue={locomotive.serie?.libelle || ''}
          defaultSelected={locomotive.serie}
          onChange={value => handleChange<Serie>(value as Serie, 'serie', locomotive.id, Engine.LOCOMOTIVES)}
          required
          minSearchLength={0}
        />
        <SelectInput
          label={terms.Pages.Mission.Form.ConvoyingStep.position}
          options={Object.keys(LocomotivePosition).map(
            position => ({ value: position, label: LocomotivePosition[position] as string }),
          )}
          onChange={value => handleChange<string>(value as string, 'position', locomotive.id, Engine.LOCOMOTIVES)}
          defaultValue={locomotive.position || 'EN_TETE'}
        />
        <CheckInput
          label={terms.Pages.Mission.Form.ConvoyingStep.ccoAssist}
          onChange={val => {
            handleCcoChecked(val)
            if (!val) handleCheckCCOAssistance()
          }}
          defaultValue={ccoChecked}
        />
        {
          ccoChecked && (
            <>
              <AutocompleteInput<Filter>
                label={terms.Pages.Mission.Form.ConvoyingStep.ccoAssist}
                apiEndpoint={filtersApi.CCO}
                resultIdKey="id"
                resultLabelKey="libelle"
                onChange={
                  value => handleChange<Filter>(value as Filter, 'ccoAssistance', locomotive.id, Engine.LOCOMOTIVES)
                }
                defaultValue={locomotive.ccoAssistance?.libelle || ''}
                defaultSelected={locomotive.ccoAssistance}
                shouldReset={shouldReset}
                required={ccoChecked}
                minSearchLength={0}
              />
              <AutocompleteInput<Entity>
                label={terms.Pages.Mission.Form.ConvoyingStep.entityAssist}
                apiEndpoint={filtersApi.ENTITY}
                resultIdKey="id"
                resultLabelKey="libelle"
                onChange={
                  value => handleChange<Entity>(value as Entity, 'entiteAssistance', locomotive.id, Engine.LOCOMOTIVES)
                }
                defaultValue={locomotive.entiteAssistance?.libelle || ''}
                defaultSelected={locomotive.entiteAssistance}
                shouldReset={shouldReset}
                required={ccoChecked}
                minSearchLength={0}
              />
            </>
          )
        }
      </div>
    </div>
  )
}
