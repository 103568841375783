import terms from 'assets/terms'
import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { FluxType, Paths } from 'types'
import { getStatusColor, saveToSession } from 'utils'
import EditIcon from '@mui/icons-material/Edit'
import Accordion from 'components/accordion/Accordion'
import SarTable from './sarTable/SarTable'

import './Flux.scss'

interface Props {
  data: FluxType
  expandedAccordion: string | null
  setExpandedAccordion: (id: string) => void
}

export default function Flux({ data, setExpandedAccordion, expandedAccordion }: Props): ReactElement {
  const sarTms = data.sartms || []

  const handleClickLink = () => {
    saveToSession('fluxId', data.id)
  }

  return (
    <div className="flux">
      <Accordion
        handleClick={() => setExpandedAccordion(data.id)}
        title={(
          <tr className="flux-item">
            <th
              style={{ gridColumn: 'span 1' }}
            >
              <Link
                to={`${Paths.FLUX}/${data.cleFlux}`}
                onClick={handleClickLink}
              >
                <EditIcon />
              </Link>
            </th>
            <th style={{ gridColumn: 'span 1' }}>
              <div className="status" style={{ background: getStatusColor(data.statutExport).chip }} />
            </th>
            <th style={{ gridColumn: 'span 4' }}>{data.cleFlux || '-'}</th>
            <th style={{ gridColumn: 'span 1' }}>{data.client?.libelle || '-'}</th>
            <th style={{ gridColumn: 'span 3' }}>{data.nombreSarTms}</th>
            <th style={{ gridColumn: 'span 1' }}>{terms.Pages.Fluxs.charged(data.estCharge)}</th>
            <th style={{ gridColumn: 'span 3' }}>{data.origine || '-'}</th>
            <th style={{ gridColumn: 'span 3' }}>{data.destination || '-'}</th>
          </tr>
        )}
        defaultExpanded={false}
        removeExpand={expandedAccordion !== data.id}
      >
        <SarTable data={sarTms} />
      </Accordion>
    </div>
  )
}
