import { useParams } from 'react-router-dom'

export default function BaremePage() {
  const params = useParams<{ baremeId: string }>()

  return (
    <div
      id="baremePage"
      className="page flex-column-center"
    >
      <h1>{`Barême Détail ${params?.baremeId}`}</h1>
    </div>
  )
}
