import terms from 'assets/terms'
import { ReactElement } from 'react'
import { filtersApi } from 'services'
import { Mission, PatchTrame } from 'types'
import { AutocompleteInput, SelectInput } from 'components'

interface Props {
  patchValue: PatchTrame
  handleChange: <T, >(key: string, value: T) => void
  possibleOd: string[]
  possibleJc: string[]
}
export default function TrameEdit({ patchValue, handleChange, possibleJc, possibleOd }: Props): ReactElement {
  return (
    <div className="edit">
      <AutocompleteInput<Mission>
        label={terms.Pages.Flux.Lot.Trame.Form.mission}
        apiEndpoint={filtersApi.MISSIONS}
        resultLabelKey="missionId"
        resultIdKey="id"
        onChange={(value: Mission) => handleChange<Mission>('mission', value || null)}
        defaultValue={patchValue?.mission?.missionId || ''}
        paginateResponse
      />
      <SelectInput
        label={terms.Pages.Flux.Lot.Trame.Form.origineDestination}
        options={possibleOd.map(item => ({ label: item, value: item }))}
        defaultValue={patchValue.origineDestination || ''}
        onChange={(value: string) => handleChange<string>('origineDestination', value)}
      />
      <SelectInput
        label={terms.Pages.Flux.Lot.Trame.Form.jourCirculation}
        options={possibleJc.map(item => ({ label: item, value: item }))}
        defaultValue={patchValue.jourCirculations || ''}
        onChange={(value: string) => handleChange<string>('jourCirculations', value)}
      />
    </div>
  )
}
