import { ReactElement, useEffect, useState } from 'react'
import { SectionDetail } from 'types'
import { ReactComponent as Train } from 'assets/icons/trainView.svg'
import { ReactComponent as TrainSelected } from 'assets/icons/trainViewSelected.svg'
import ArrowRightIcon from '@mui/icons-material/ArrowForward'

import './Timeline.scss'
import terms from 'assets/terms'

interface Props {
  selectedId: string;
  data: Partial<SectionDetail>[];
  type?: 'section' | 'step';
}
export default function Timeline({ selectedId, data, type = 'section' }: Props): ReactElement {
  const [timeData, setTimeData] = useState<Partial<SectionDetail>[]>([])
  useEffect(() => { setTimeData(data) }, [data])
  if (!data) return null

  return (
    <div className="timeline">
      {
        timeData.map((item, index) => {
          const isSelected = item.id === selectedId
          const isPreviousSelected = index < data.length - 1 && data[index + 1].id === selectedId

          return (
            <div key={item.id} className={`timeline${isSelected ? ' highlighted' : ''}`}>
              {
                index === 0 && (
                  <div className="stage">
                    <div className="chip" />
                    <span className="place">
                      {item.arretDepart?.lieu?.ngramme}
                    </span>
                    <span className="hour">
                      {item.arretDepart?.heureDepart.slice(0, 5)}
                    </span>
                  </div>
                )
              }
              <div className="line" />
              <div className="section-name">
                <div>
                  {
                    isSelected ? <TrainSelected /> : <Train />
                  }
                </div>
                <span className="libelle">
                  {`${type === 'section'
                    ? terms.Pages.Mission.Form.Section.section(false)
                    : terms.Pages.Mission.Form.ConvoyingStep.title(false)} ${index + 1}`}
                </span>
              </div>
              <div className="line" />
              <div className={`stage${isPreviousSelected ? ' previous-highlighted' : ''}`}>
                <div className="chip" />
                <span className="place">
                  {item.arretArrivee?.lieu?.ngramme}
                </span>
                <div className="hour">
                  <span>
                    {item.arretArrivee?.heureArrivee.slice(0, 5)}
                  </span>
                  {
                    timeData[index + 1]?.arretDepart && timeData[index + 1].arretDepart?.heureDepart !== '00:00:00' && (
                      <div>
                        <ArrowRightIcon />
                        <span>
                          {timeData[index + 1].arretDepart?.heureDepart.slice(0, 5) || ''}
                        </span>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}
