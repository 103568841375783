import { ReactElement } from 'react'
import Button from '@mui/material/Button'

import './Button.scss'

export enum ButtonStyle {
  primary = 'primary',
  light = 'light',
  borderLess = 'borderLess',
  borderLessPrimary = 'borderLessPrimary',
  borderLessWhite = 'borderLessWhite',
  delete = 'delete',
}

export enum ButtonSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  xlarge = 'xlarge',
}

type Props = {
  text: string;
  onClick?: () => void;
  style?: ButtonStyle;
  disabled?: boolean;
  icon?: ReactElement;
  size?: ButtonSize;
}

export default function SimpleButton({
  text,
  onClick,
  style = ButtonStyle.primary,
  size = ButtonSize.small,
  disabled = false,
  icon = null,
}: Props): ReactElement {
  return (
    <Button
      className={`simple-button button--${size} button--${style}${disabled ? ' disabled' : ''}`}
      variant="contained"
      size="large"
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      {text}
    </Button>
  )
}
