import terms from 'assets/terms'
import { ReactElement, useEffect, useState } from 'react'
import { MissionSignal, VariantAvailabilitySignal, VariantSignal, deleteVariant, patchVariant } from 'services'
import { OnOpenDelete, Variant } from 'types'
import {
  Button, ButtonStyle, CheckInput, Loader,
} from 'components'
import { sortDays, weeklyDays } from 'utils'
import { v4 as uuidV4 } from 'uuid'

import './MissionVariant.scss'

export default function MissionVariant({ onOpenDelete }: OnOpenDelete): ReactElement {
  const mission = MissionSignal.value
  const variant = VariantSignal.value
  const variantAvailability = VariantAvailabilitySignal.value

  const [currentVariant, setCurrentVariant] = useState<Variant>(variant)

  useEffect(() => { setCurrentVariant(variant) }, [variant])

  if ((!variant || !variantAvailability || !mission || !currentVariant)) {
    return (
      <div className="loading-wrapper">
        <Loader />
      </div>
    )
  }

  function handleChange<T>(val: T, key: string) {
    setCurrentVariant({ ...currentVariant, [key]: val })
    patchVariant(mission.id, { ...currentVariant, [key]: val })
  }

  return (
    <div className="mission-variant">
      <h3>
        {`${terms.Pages.Mission.SideMenu.variant} ${currentVariant?.jourHebdo.map(day => day.slice(0, 2)).join('')}`}
      </h3>
      <CheckInput
        label={terms.Pages.Mission.Form.Variant.defaultVariant}
        defaultValue={currentVariant?.estParDefaut}
        onChange={() => handleChange<boolean>(!currentVariant?.estParDefaut, 'estParDefaut')}
      />
      <div className="form">
        <span>
          {terms.Pages.Mission.Form.Variant.circulationDays}
        </span>
        {weeklyDays.map(day => (
          <CheckInput
            key={uuidV4()}
            label={day}
            disabled={variantAvailability?.jourHebdoPris?.includes(day)}
            defaultValue={currentVariant?.jourHebdo?.concat(variantAvailability?.jourHebdoPris).includes(day)}
            onChange={() => handleChange<string[]>(
              currentVariant.jourHebdo.includes(day)
                ? currentVariant?.jourHebdo.filter(jour => jour !== day)
                : currentVariant?.jourHebdo.concat(day).sort(sortDays),
              'jourHebdo',
            )}
          />
        ))}
      </div>
      <div className="buttons">
        <Button
          text={terms.Common.delete}
          onClick={() => onOpenDelete(
            terms.Pages.Mission.Form.Variant.Modal.deleteText,
            () => deleteVariant(mission.id, currentVariant.id),
          )}
          style={ButtonStyle.delete}
        />
      </div>
    </div>
  )
}
