import { Loader } from 'components'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { MatrixSignal, getMatrix } from 'services'
import { v4 as uuidV4 } from 'uuid'
import MissionsHeader from './header/MissionsHeader'
import MissionsSection from './section/MissionSection'

import './MissionsPage.scss'

export default function MissionsPage() {
  const LOADING_TIMEOUT = 2000
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getMatrix().then(() => {
      setTimeout(() => {
        setLoading(false)
      }, LOADING_TIMEOUT)
    })
  }, [])

  if (!MatrixSignal.value) {
    return (
      <div
        id="missionsPage"
        className="page"
      >
        <MissionsHeader />
        <div className="loading-wrapper">
          <Loader />
        </div>
      </div>
    )
  }

  const handleNextPage = () => {
    if (!MatrixSignal.value) return
    setLoading(true)
    if (MatrixSignal.value.next) {
      getMatrix(MatrixSignal.value.next).then(() => {
        setTimeout(() => {
          setLoading(false)
        }, LOADING_TIMEOUT)
      })
    }
  }

  return (
    <div
      id="missionsPage"
      className="page"
    >
      <MissionsHeader />
      <InfiniteScroll
        dataLength={MatrixSignal.value.results.length}
        next={handleNextPage}
        hasMore={!!MatrixSignal.value.next}
        loader={loading && (
        <div className="flex-column-center">
          <Loader />
        </div>
        )}
        height="calc(100vh - 210px)"
        scrollableTarget="missionsPage"
      >
        <div className="missions flex-column-center">
          {
            MatrixSignal.value.results.map(matrix => (
              <MissionsSection key={uuidV4()} matrix={matrix} />
            ))
          }
        </div>
      </InfiniteScroll>
    </div>
  )
}
