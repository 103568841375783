import { ReactElement, StrictMode, Suspense, useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import '@preact/signals-react/auto'
import { auth } from '@osrdata/app_core'
import { useAppDispatch, useAppSelector } from 'utils'
import { terms } from 'assets/terms'
import { useRoles } from 'services'
import { Loader, TopBar, ModalWrapper, ToastMessage } from 'components'
import { DeniedPage } from 'pages'
import Router from 'Router'

import 'App.scss'

const APP_NAME = 'APT'

export default function App(): ReactElement {
  const dispatch = useAppDispatch()
  const { isLogged, isLoading } = useAppSelector(state => state.user)
  const { hasAccess, rolesLoaded } = useRoles()
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    setAccessDenied(rolesLoaded && isLogged && !hasAccess)
  }, [isLogged, isLoading, hasAccess, rolesLoaded])

  const renderApp = () => {
    if (isLoading) return <Loader message={terms.Common.loading} standalone />
    if (accessDenied) return <DeniedPage />

    return (
      <>
        <Router />
        <ModalWrapper />
      </>
    )
  }

  if (!isLoading && !isLogged) return null

  return (
    <StrictMode>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          {!isLoading && <TopBar appName={APP_NAME} />}
          <div id="app">{renderApp()}</div>
          <ToastMessage />
        </BrowserRouter>
      </Suspense>
    </StrictMode>
  )
}
