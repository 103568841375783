import terms from 'assets/terms'
import { ReactElement } from 'react'
import { ExportStatus, Paths } from 'types'
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg'
import { MissionSignal, exportMission, TaskSignal } from 'services'
import { ModalSignal, LoadingModal, ImportModal } from 'components'
import Button, { ButtonSize, ButtonStyle } from 'components/button/Button'
import { ModalType } from 'components/modals/ModalWrapper'
import { getStatusColor } from 'utils'
import { DisabledModalButtonSignal } from 'components/modals/import/ImportModal'

import './MissionHeader.scss'

export default function MissionHeader(): ReactElement {
  const handleOpenModal = (type: ModalType) => {
    DisabledModalButtonSignal.value = false
    switch (type) {
      case ModalType.WARNING:
        if (!ModalSignal.value) {
          ModalSignal.value = (
            <ImportModal
              title={terms.Pages.Mission.Import.missionIdImport(MissionSignal.value.missionId)}
              info={[terms.Pages.Mission.Import.infoNoReimport]}
              type={ModalType.WARNING}
              textButton={terms.Common.continue}
              handleClickConfim={() => exportMission(MissionSignal.value.id, handleOpenModal)}
            />
          )
        }
        break
      case ModalType.SUCCESS:
        if (TaskSignal.value.status === ExportStatus.SUCCESS) {
          ModalSignal.value = (
            <ImportModal
              title={terms.Pages.Mission.Import.success}
              type={ModalType.SUCCESS}
              textButton={terms.Common.close}
            />
          )
        }
        break
      case ModalType.ERROR:
        if (TaskSignal.value.status === ExportStatus.FAILURE) {
          ModalSignal.value = (
            <ImportModal
              title={terms.Pages.Mission.Import.failure}
              info={TaskSignal.value?.traceback.map(item => item.message) || []}
              type={ModalType.ERROR}
              textButton={terms.Common.close}
            />
          )
        }
        break
      case ModalType.LOADING:
        ModalSignal.value = (
          <LoadingModal message={terms.Pages.Mission.Import.loading} />
        )
        break
      default:
        break
    }
  }

  return (
    <div className="mission-header">
      <div className="title">
        <Button
          text={terms.Common.back}
          icon={<ArrowLeft />}
          style={ButtonStyle.borderLessWhite}
          onClick={() => { window.location.href = Paths.MISSIONS }}
        />
        <div>
          {`${terms.Pages.Missions.mission} ${MissionSignal.value.missionId || ''}`}
        </div>
        <span style={{ backgroundColor: getStatusColor(MissionSignal.value.statutExport).chip }} />
      </div>
      <div className="import-button">
        <Button
          text={terms.Common.RusImport}
          onClick={() => handleOpenModal(ModalType.WARNING)}
          disabled={MissionSignal.value.statutExport === ExportStatus.SUCCESS}
          style={ButtonStyle.primary}
          size={ButtonSize.xlarge}
        />
      </div>
    </div>
  )
}
