import './SwitchInput.scss'

type Props = {
  label?: string;
}

export default function SwitchInput({ label }: Props) {
  return (
    <div className="switch-input">
      <label htmlFor={label}>{label}</label>
      <input id={label} type="checkbox" />
    </div>
  )
}
