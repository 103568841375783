import terms from 'assets/terms'
import { ReactElement, useState } from 'react'
import {
  MissionSignal,
  VariantSectionsSignal,
  VariantSignal,
  VariantStepsSignal,
  VariantsSignal,
  getMission,
  getVariant,
  getVariantAvailability,
  getVariantSection,
  getVariantSections,
  getVariantStep,
  getVariantSteps,
  postVariant,
  postVariantSection,
  postVariantStep,
} from 'services'
import { MissionElement, SectionDetail, Step, Variant } from 'types'
import { sortDays } from 'utils'
import Accordion from 'components/accordion/Accordion'
import { ReactComponent as Home } from 'assets/icons/home.svg'
import AddIcon from '@mui/icons-material/Add'

import './MissionSideMenu.scss'

interface Props {
  missionId: string
  setElementSelected: (element: MissionElement) => void
  elementSelected: MissionElement
}

export default function MissionSideMenu({
  missionId,
  setElementSelected,
  elementSelected,
}: Props): ReactElement {
  const [sectionSelected, setSectionSelected] = useState<string | null>(null)
  const [stepSelected, setStepSelected] = useState<string | null>(null)
  const [engineSelected, setEngineSelected] = useState<string | null>(null)
  const [variantSelected, setVariantSelected] = useState<string | null>(null)
  const id = MissionSignal.value?.id

  const getTimelineList = (variantId: string) => {
    getVariantSections(MissionSignal.value.id, variantId)
    getVariantSteps(MissionSignal.value.id, variantId)
  }

  const handleSelect = <T, >(type: MissionElement, element?: T) => {
    setElementSelected(type)
    switch (type) {
      case MissionElement.VARIANT:
        setVariantSelected((element as Variant).id)
        setEngineSelected(null)
        getVariant(MissionSignal.value.id, (element as Variant).id)
        getVariantAvailability(MissionSignal.value.id, (element as Variant).id)
        getTimelineList((element as Variant).id)
        break
      case MissionElement.SECTION:
        setSectionSelected((element as SectionDetail).id)
        getVariantSection(MissionSignal.value.id, VariantSignal.value.id, (element as SectionDetail).id)
        getTimelineList(VariantSignal.value.id)
        break
      case MissionElement.STEP:
        setStepSelected((element as Step).id)
        setEngineSelected(null)
        getVariantStep(MissionSignal.value.id, VariantSignal.value.id, (element as Step).id)
        getTimelineList(VariantSignal.value.id)
        break
      case MissionElement.ENGINE:
        setEngineSelected((element as string))
        break
      default:
        setSectionSelected(null)
        setEngineSelected(null)
        setStepSelected(null)
        setVariantSelected(null)
        getMission(id)
        break
    }
  }

  const handleAdd = (type: MissionElement, variantId?: string) => {
    switch (type) {
      case MissionElement.VARIANT:
        postVariant(MissionSignal.value.id, { jourHebdo: [] }).then(() => {
          handleSelect<Variant>(
            MissionElement.VARIANT,
            VariantsSignal.value[VariantsSignal.value.length - 1],
          )
        })
        break
      case MissionElement.SECTION:
        postVariantSection(MissionSignal.value.id, variantId, {}).then(() => {
          handleSelect<SectionDetail>(
            MissionElement.SECTION,
            VariantSectionsSignal.value[VariantSectionsSignal.value.length - 1],
          )
        })
        break
      case MissionElement.STEP:
        postVariantStep(MissionSignal.value.id, variantId, {}).then(() => {
          handleSelect<Step>(
            MissionElement.STEP,
            VariantStepsSignal.value[VariantStepsSignal.value.length - 1],
          )
        })
        break
      default:
        break
    }
  }

  return (
    <div className="mission-side-menu">
      <div className={elementSelected === MissionElement.HOME ? 'selected' : ''}>
        <button
          type="button"
          className="button"
          onClick={() => handleSelect<boolean>(MissionElement.HOME)}
        >
          <span>
            {`${terms.Pages.Missions.mission} ${missionId}`}
          </span>
          <Home />
        </button>
      </div>
      <div className="accordions">
        {
          VariantsSignal.value && VariantsSignal.value.map(variant => (
            <Accordion
              key={variant.id}
              title={(
                <span>
                  {
                  `${terms.Pages.Mission.SideMenu.variant} ${variant.jourHebdo
                    .sort(sortDays)
                    .map(day => day.slice(0, 2))
                    .join('')
                  }`
                }
                </span>
              )}
              handleClick={() => handleSelect<Variant>(MissionElement.VARIANT, variant)}
              defaultExpanded={variantSelected === variant.id}
              removeExpand={variantSelected !== variant.id}
            >
              <div className="accodion-content">
                <Accordion
                  title={<span>{terms.Pages.Mission.Form.Section.section(true)}</span>}
                  defaultExpanded={false}
                >
                  <div className="section-button">
                    {VariantSectionsSignal.value.map((section, index) => (
                      <div key={section.id} className={sectionSelected === section.id ? 'selected' : ''}>
                        <button
                          type="button"
                          onClick={() => handleSelect<SectionDetail>(MissionElement.SECTION, section)}
                          className="button"
                        >
                          {`${terms.Pages.Mission.Form.Section.section(false)} 
                        ${index + 1}/${VariantSectionsSignal.value.length}`}
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => handleAdd(MissionElement.SECTION, variant.id)}
                      className="add"
                    >
                      <AddIcon className="icon" />
                    </button>
                  </div>
                </Accordion>
                <Accordion
                  title={<span>{terms.Pages.Mission.Form.ConvoyingStep.title(true)}</span>}
                  defaultExpanded={false}
                >
                  <div className="section-button">
                    {
                      VariantStepsSignal.value.map((step, index) => (
                        <Accordion
                          key={step.id}
                          title={(
                            <span>
                              {
                                `${terms.Pages.Mission.Form.ConvoyingStep.step} 
                                ${index + 1}/${VariantStepsSignal.value.length}`
                              }
                            </span>
                          )}
                          handleClick={() => handleSelect<Step>(MissionElement.STEP, step)}
                          defaultExpanded={stepSelected === step.id}
                          removeExpand={stepSelected !== step.id}
                        >
                          <div
                            key={`engine-${step.id}`}
                            className={`engine${`engine-${step.id}` === engineSelected ? ' selected' : ''}`}
                          >
                            <button
                              type="button"
                              className="button"
                              onClick={() => handleSelect<string>(MissionElement.ENGINE, `engine-${step.id}`)}
                            >
                              {terms.Pages.Mission.Form.ConvoyingStep.engineButton}
                            </button>
                          </div>
                        </Accordion>
                      ))
                    }
                    <button
                      type="button"
                      onClick={() => handleAdd(MissionElement.STEP, variant.id)}
                      className="add"
                    >
                      <AddIcon className="icon" />
                    </button>
                  </div>
                </Accordion>
              </div>
            </Accordion>
          ))
        }
        <button
          type="button"
          onClick={() => handleAdd(MissionElement.VARIANT)}
          className="button"
        >
          <span>
            {terms.Pages.Mission.SideMenu.addVariant}
          </span>
          <AddIcon className="icon" />
        </button>
      </div>
    </div>
  )
}
