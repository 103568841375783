import { ReactElement, useEffect, useState } from 'react'
import {
  MissionSignal,
  VariantSignal,
  VariantStepSignal,
  VariantStepsSignal,
  deleteAgent,
  deleteLocomotive,
  patchVariantStep,
  postAgent,
  postLocomotive,
} from 'services'
import { Step } from 'types'
import Timeline from 'components/diagram/Timeline'
import Locomotives from './Locomotives/Locomotives'
import DriverAgents from './DriverAgents/DriverAgents'

import './MissionEngine.scss'

export enum Engine {
  LOCOMOTIVES = 'locomotives',
  AGENTS = 'agents',
}

export default function MissionEngine(): ReactElement {
  const mission = MissionSignal.value
  const variant = VariantSignal.value
  const step = VariantStepSignal.value
  const steps = VariantStepsSignal.value

  const [currentStep, setCurrentStep] = useState<Step>(step)

  useEffect(() => {
    setCurrentStep(step)
  }, [step])

  const handleChange = <T, >(value: T, key: string, id: string, type: Engine) => {
    setCurrentStep({
      ...currentStep,
      [type]: currentStep[type].map(item => {
        if (item.id === id) {
          return {
            ...item,
            [key]: value,
          }
        }
        return item
      }),
    })
    patchVariantStep(
      mission.id,
      variant.id,
      {
        id: currentStep.id,
        [type]: currentStep[type].map(item => {
          if (item.id === id) {
            return {
              ...item,
              [key]: value,
            }
          }
          return item
        }),
      },
    )
  }

  const handleAdd = (type: Engine) => {
    switch (type) {
      case 'locomotives':
        postLocomotive(mission.id, variant.id, currentStep.id, {}, currentStep)
        break
      case 'agents':
        postAgent(mission.id, variant.id, currentStep.id, {}, currentStep)
        break
      default:
        break
    }
  }

  const handleDelete = (type: string, id: string) => {
    switch (type) {
      case 'locomotive':
        deleteLocomotive(mission.id, variant.id, currentStep.id, id)
        break
      case 'agent':
        deleteAgent(mission.id, variant.id, currentStep.id, id)
        break
      default:
        break
    }
  }

  return (
    <div className="variant-step-engine">
      <div className="header">
        <Timeline
          selectedId={step?.id}
          data={steps}
          type="step"
        />
      </div>
      <div className="form-container">
        <div>
          <Locomotives
            handleDelete={handleDelete}
            currentStep={currentStep}
            handleChange={handleChange}
            handleAdd={() => handleAdd(Engine.LOCOMOTIVES)}
          />
        </div>
        <div>
          <DriverAgents
            currentStep={currentStep}
            handleDelete={handleDelete}
            handleChange={handleChange}
            handleAdd={() => handleAdd(Engine.AGENTS)}
          />
        </div>
      </div>
    </div>
  )
}
