/* eslint-disable react/no-danger */
import { terms } from 'assets/terms'
import { SelectedAxeSignal, SelectedTubeSignal } from 'services'
import { Button, FilterModal, ModalSignal, SwitchInput } from 'components'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg'
import { effect } from '@preact/signals-react'

import './MissionsHeader.scss'

export default function MissionsHeader() {
  const handleOpenModal = () => {
    if (!ModalSignal.value) ModalSignal.value = <FilterModal />
  }

  effect(() => {
    if (!SelectedAxeSignal.value || !SelectedTubeSignal.value) handleOpenModal()
  })
  return (
    <div className="missions-header">
      <div className="actions flex-center">
        <Button
          text={terms.Pages.Missions.addMission}
          icon={<PlusIcon />}
          disabled
        />
        <div className="filter" onClick={handleOpenModal}>
          <span>{`Matrice ${SelectedAxeSignal.value?.libelle || ''}`}</span>
          <span dangerouslySetInnerHTML={{ __html: SelectedTubeSignal.value?.libelle }} />
          <ChevronDown />
        </div>
        <SwitchInput label={terms.Pages.Missions.switchDisplayGroup} />
      </div>

      <div className="week-days flex-center">
        {terms.Common.weekdays.map(day => (
          <div key={day} className="day">
            {day}
          </div>
        ))}
      </div>
    </div>
  )
}
