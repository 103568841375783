import { ReactElement } from 'react'
import { Loader } from 'components'

import './LoadingModal.scss'

interface Props {
  message?: string
}

export default function LoadingModal({ message }: Props): ReactElement {
  return (
    <div className="modal-loading">
      <Loader variant="large" />
      <div className="content">
        {message}
      </div>
    </div>
  )
}
