import terms from 'assets/terms'
import { ReactElement } from 'react'
import { Step } from 'types'
import AddIcon from '@mui/icons-material/Add'
import { Locomotive } from './Locomotive'
import { Engine } from '../MissionEngine'

interface Props {
  currentStep: Step
  handleDelete: (type: string, id: string) => void
  handleChange: <T>(value: T, key: string, id: string, type: Engine) => void
  handleAdd: () => void
}

export default function Locomotives({ handleDelete, handleChange, currentStep, handleAdd }: Props): ReactElement {
  return (
    <div>
      <h3>
        {terms.Pages.Mission.Form.ConvoyingStep.driveEngines}
      </h3>
      <div className="forms">
        {
          currentStep?.locomotives?.map((locomotive, index) => (
            <Locomotive
              key={locomotive.id}
              locomotive={locomotive}
              index={index}
              handleChange={handleChange}
              handleDelete={handleDelete}
            />
          ))
        }
        <div className="add">
          <button
            type="button"
            onClick={handleAdd}
          >
            <AddIcon />
            <span>
              {terms.Pages.Mission.Form.ConvoyingStep.addLocomotive}
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}
