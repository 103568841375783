import { Loader } from 'components'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FluxsSignal, getFluxs } from 'services'
import terms from 'assets/terms'
import Flux from './flux/Flux'
import FluxsHeader from './header/FluxsHeader'

import './FluxsPage.scss'

export default function FluxsPage() {
  const LOADING_TIMEOUT = 2000
  const [loading, setLoading] = useState(false)
  const [expdandedAccordion, setExpandedAccordion] = useState<string | null>(null)

  useEffect(() => {
    getFluxs().then(() => {
      setTimeout(() => {
        setLoading(false)
      }, LOADING_TIMEOUT)
    })
  }, [])

  if (!FluxsSignal.value) {
    return (
      <div
        id="fluxsPage"
        className="page"
      >
        <FluxsHeader />
        <div className="loading-wrapper">
          <Loader />
        </div>
      </div>
    )
  }

  const handleNextPage = () => {
    if (!FluxsSignal.value) return
    setLoading(true)
    if (FluxsSignal.value.next) {
      getFluxs(FluxsSignal.value.next).then(() => {
        setTimeout(() => {
          setLoading(false)
        }, LOADING_TIMEOUT)
      })
    }
  }

  const tableHeaderInfo = {
    [terms.Pages.Fluxs.Table.Header.status]: 1,
    [terms.Pages.Fluxs.Table.Header.flux]: 4,
    [terms.Pages.Fluxs.Table.Header.client]: 1,
    [terms.Pages.Fluxs.Table.Header.sarNumber]: 3,
    [terms.Pages.Fluxs.Table.Header.state]: 1,
    [terms.Pages.Fluxs.Table.Header.origin]: 3,
    [terms.Pages.Fluxs.Table.Header.destination]: 3,
  }
  return (
    <div
      id="fluxsPage"
      className="page"
    >
      <FluxsHeader />
      <div className="table-header">
        <table>
          <thead>
            <tr>
              <th className="header-1" />
              {Object.entries(tableHeaderInfo).map(([key, value]) => (
                <th key={key} style={{ gridColumn: `span ${value}` }}>{key}</th>
              ))}
              <th className="header-9" />
            </tr>
          </thead>
        </table>
      </div>
      <InfiniteScroll
        dataLength={FluxsSignal.value.results.length}
        next={handleNextPage}
        hasMore={!!FluxsSignal.value.next}
        loader={loading && (
        <div className="flex-column-center">
          <Loader />
        </div>
        )}
        height="calc(100vh - 300px)"
        scrollableTarget="fluxsPage"
      >
        <div className="fluxs flex-column-center">
          {
            FluxsSignal.value.results.map(item => (
              <Flux
                key={item.id}
                data={item}
                expandedAccordion={expdandedAccordion}
                setExpandedAccordion={setExpandedAccordion}
              />
            ))
          }
        </div>
      </InfiniteScroll>
    </div>
  )
}
