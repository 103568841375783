import terms from 'assets/terms'
import { ReactElement } from 'react'
import { Step } from 'types'
import AddIcon from '@mui/icons-material/Add'
import DriverAgent from './DriverAgent'
import { Engine } from '../MissionEngine'

interface Props {
  currentStep: Step
  handleDelete: (type: string, id: string) => void
  handleChange: <T>(value: T, key: string, id: string, type: Engine) => void
  handleAdd: () => void
}

export default function DriverAgents({ currentStep, handleDelete, handleChange, handleAdd }: Props): ReactElement {
  return (
    <div>
      <h3>
        {terms.Pages.Mission.Form.ConvoyingStep.driverAgents}
      </h3>
      <div className="forms">
        {
          currentStep?.agents?.map((agent, index) => (
            <DriverAgent
              key={agent.id}
              agent={agent}
              index={index}
              handleChange={handleChange}
              handleDelete={handleDelete}
            />
          ))
        }
        <div className="add">
          <button
            type="button"
            onClick={handleAdd}
          >
            <AddIcon />
            <span>
              {terms.Pages.Mission.Form.ConvoyingStep.addAgent}
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}
