import terms from 'assets/terms'
import { ReactElement } from 'react'
import { filtersApi } from 'services'
import { Agent, TypeAgent } from 'types'
import { ReactComponent as Trash } from 'assets/icons/trash.svg'
import { AutocompleteInput } from 'components'
import { Engine } from '../MissionEngine'

interface Props {
  agent: Agent
  handleDelete: (type: string, id: string) => void
  handleChange: <T>(value: T, key: string, id: string, type: Engine) => void
  index: number
}

export default function DriverAgent({ handleChange, handleDelete, index, agent }: Props): ReactElement {
  return (
    <div className="form">
      <div className="title">
        <span>
          {`${terms.Pages.Mission.Form.ConvoyingStep.driverAgent} ${index + 1}`}
        </span>
        <button
          type="button"
          className="button"
          onClick={() => handleDelete('agent', agent.id)}
        >
          <Trash />
        </button>
      </div>
      <div className="inputs">
        <AutocompleteInput<TypeAgent>
          label={terms.Pages.Mission.Form.ConvoyingStep.codeTypeAgent}
          apiEndpoint={filtersApi.TYPE_AGENT}
          resultIdKey="id"
          resultLabelKey="libelle"
          defaultValue={agent.typeAgent?.libelle || ''}
          defaultSelected={agent.typeAgent}
          onChange={value => handleChange<TypeAgent>(value as TypeAgent, 'typeAgent', agent.id, Engine.AGENTS)}
          required
          minSearchLength={0}
        />
      </div>
    </div>
  )
}
